import Uploader from '@/components/Uploader'
import { createQuestion, RecommendQuestion, updateQuestion } from '@/services/recommendQuestion'
import { App, Button, Col, Drawer, Form, Input, Row, Space } from 'antd'
import { useEffect, useState } from 'react'

const { Item: FormItem } = Form

export default function ItemForm({ open, defaultValue, onSuccess, onClose }: { open: boolean; defaultValue?: RecommendQuestion; onSuccess: () => void; onClose: () => void }) {
  const { message } = App.useApp()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    await form.validateFields()

    setLoading(true)
    try {
      const data = form.getFieldsValue(true)

      if (defaultValue && defaultValue.id) {
        await updateQuestion(defaultValue.id, data)
      } else {
        await createQuestion(data)
      }

      onSuccess()
    } catch(e) {
      if (e instanceof Error) {
        message.error(e.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const actions = (
    <Space>
      <Button onClick={onClose}>Cancel</Button>
      <Button type="primary" loading={loading} onClick={handleSubmit}>Save</Button>
    </Space>
  )

  useEffect(() => {
    if (open) {
      if (defaultValue) {
        form.setFieldsValue(defaultValue)
      } else {
        form.resetFields()
      }
    }
  }, [defaultValue, form, open])

  return (
    <Drawer open={open} width={720} title="Recommend Question" extra={actions} onClose={onClose}>
      <Form form={form} layout="vertical">
        <FormItem<RecommendQuestion>
          label="Display name:"
          name="question"
          rules={[{ required: true, message: 'Please input display name' }]}
        >
          <Input />
        </FormItem>
        <FormItem<RecommendQuestion> label="LLM query:" name="llmQuery">
          <Input />
        </FormItem>
        <FormItem<RecommendQuestion> label="Search query:" name="questionForQuery">
          <Input />
        </FormItem>
        <Row gutter={20}>
          <Col span={12}>
            <FormItem<RecommendQuestion>
              label="PC image:"
              name="imageUrl"
              rules={[{ required: true, message: 'Please upload PC image' }]}
            >
              <Uploader path="banner_image" className="h-24" />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem<RecommendQuestion> label="Mobile image:" name="mobileImageUrl">
              <Uploader path="banner_image" className="h-24" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={6}>
            <FormItem<RecommendQuestion>
              label="User avatar:"
              name="avatar"
              rules={[{ required: true, message: 'Please upload user avatar' }]}
            >
              <Uploader path="banner_image" className="h-20" />
            </FormItem>
          </Col>
          <Col span={18}>
            <FormItem<RecommendQuestion> label="Username:" name="username">
              <Input />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Drawer>
  )
}