import { App, Button, Descriptions, Table, TableProps } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import ItemForm from './components/ItemForm'
import { queryQuestions, type RecommendQuestion } from '@/services/recommendQuestion'
import dayjs from 'dayjs'
import Actions from './components/Actions'
import Pagination from '@/components/Pagination'
import { HiOutlinePlus } from 'react-icons/hi'

const { Item: DescriptionItem } = Descriptions

export default function RecommendQuestions() {
  const { message } = App.useApp()
  const [open, setOpen] = useState(false)
  const [list, setList] = useState<RecommendQuestion[]>([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [question, setQuestion] = useState<RecommendQuestion>()

  const handleNextPage = () => {
    setPage(prev => prev + 1)
  }

  const handlePrevPage = () => {
    setPage(prev => Math.max(0, prev - 1))
  }

  const fetchList = useCallback(async () => {
    setLoading(true)
    try {
      const res = await queryQuestions(page, 30)
      if (res && res.length) {
        setList(res)
      }
    } catch(e) {
      if (e instanceof Error) {
        message.error(e.message)
      }
    } finally {
      setLoading(false)
    }
  }, [page, message])

  const handleAdd = () => {
    setOpen(true)
    setQuestion(undefined)
  }

  const handlDrawerClose = () => {
    setOpen(false)
  }

  const handleDrawerSuccess = () => {
    setOpen(false)
    setQuestion(undefined)

    fetchList()
  }

  const handleEdit = (id: string) => {
    const res = list.find(v => v.id === id)
    if (!res) {
      return
    }

    setQuestion(res)
    setOpen(true)
  }

  const columns: TableProps<RecommendQuestion>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'PC Image',
      dataIndex: 'imageUrl',
      key: 'pcUrl',
      render: text => <img className="max-h-24 max-w-24" src={text} />
    },
    {
      title: 'Display Name',
      dataIndex: 'question',
      key: 'displayName'
    },
    {
      title: 'Update Time',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: text => dayjs(text).format('YYYY-MM-DD hh:mm:ss')
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'id',
      render: id => <Actions id={id} onEdit={handleEdit} onArchived={fetchList} />
    }
  ]

  const renderExpandedRow = (record: RecommendQuestion) => {
    return (
      <Descriptions bordered>
        <DescriptionItem label="LLM Query">{record.llmQuery}</DescriptionItem>
        <DescriptionItem label="Search Query" span={2}>{record.questionForQuery}</DescriptionItem>
        <DescriptionItem label="Mobile Image" span={3}>
          {record.mobileImageUrl && <img className="h-24" src={record.mobileImageUrl} />}
        </DescriptionItem>
        <DescriptionItem label="Username">{record.username}</DescriptionItem>
        <DescriptionItem label="User Avatar">
          {record.avatar && <img className="h-24" src={record.avatar} />}
        </DescriptionItem>
      </Descriptions>
    )
  }

  useEffect(() => {
    fetchList()
  }, [fetchList])

  return (
    <section className="grid grid-rows-[40px_1fr_32px] gap-y-4" style={{ height: 'calc(100vh - 64px)' }}>
      <div>
        <Button type="primary" size="large" icon={<HiOutlinePlus />} onClick={handleAdd}>Add</Button>
      </div>
      <div className="overflow-auto">
        <Table
          rowKey="id"
          columns={columns}
          dataSource={list}
          loading={loading}
          expandable={{ expandedRowRender: renderExpandedRow }}
          pagination={false}
        />
      </div>
      <Pagination
        disablePrev={page === 1 || loading}
        disableNext={list.length < 30 || loading}
        onPrev={handlePrevPage}
        onNext={handleNextPage}
      />
      <ItemForm open={open} defaultValue={question} onSuccess={handleDrawerSuccess} onClose={handlDrawerClose} />
    </section>
  )
}