import request from '@/utils/request'

export interface Favicon {
  id: string
  domain: string
  faviconUrl: string
  originUrl: string
  operator: string
  updateAt: string
}

export interface FaviconResponse {
  _id: string
  domain: string
  favicon_url: string
  origin_url: string
  operator: string
  update_at: string
}

export type FaviconData = Pick<Favicon, 'domain' | 'faviconUrl' | 'originUrl'>

export async function queryFavicons(page: number, pageSize: number, domain?: string) {
  const res = await request.get<{ data: FaviconResponse[]; total: number }>(`/favicons?page=${page}&limit=${pageSize}&domain=${domain}`)

  const list = (res?.data ?? []).map<Favicon>(v => ({
    id: v._id,
    domain: v.domain,
    faviconUrl: v.favicon_url,
    originUrl: v.origin_url,
    operator: v.operator,
    updateAt: v.update_at
  }))

  return { list, total: res?.total ?? 0 }
}

export async function addFavicon(data: FaviconData) {
  return request.post('/favicons', {
    domain: data.domain,
    favicon_url: data.faviconUrl,
    origin_url: data.originUrl
  })
}

export function updateFavicon(id: string, data: FaviconData) {
  return request.post(`/favicons/${id}`, {
    domain: data.domain,
    favicon_url: data.faviconUrl,
    origin_url: data.originUrl
  })
}
