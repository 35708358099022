import { App, Button, Col, Drawer, Form, FormInstance, Row, Space, Table, Tooltip, type TableProps } from 'antd'
import { ReactNode, useState } from 'react'
import { HiOutlinePencil, HiOutlinePlus } from 'react-icons/hi'

export interface TableListProps<T extends object, R = T> {
  tableProps: TableProps<T>
  formTitle?: string
  addButtonText?: string
  hideAdd?: boolean
  hideEdit?: boolean | ((record: T) => boolean)
  headerLeft?: ReactNode
  headerActions?: ReactNode
  children?: ReactNode | ((form: FormInstance<R>, isEdit?: boolean) => ReactNode)
  listActions?: (record: T) => ReactNode
  onAdd?: (form: FormInstance<R>) => void
  onEdit?: (form: FormInstance<R>, record: T) => void
  onSubmit?: (form: FormInstance<R>, record?: T, isEdit?: boolean) => Promise<void>
}

export default function TableList<T extends object, R = T>({ tableProps, formTitle, addButtonText, headerLeft, hideAdd, hideEdit, headerActions, children, listActions, onSubmit, onAdd, onEdit }: TableListProps<T, R>) {
  const [form] = Form.useForm<R>()
  const { message } = App.useApp()
  const [current, setCurrent] = useState<T>()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const handleAdd = () => {
    form.resetFields()

    setCurrent(undefined)
    setOpen(true)
    setIsEdit(false)

    onAdd?.(form)
  }

  const handleEdit = (record: T) => {
    form.setFieldsValue(record)

    setCurrent(record)
    setOpen(true)
    setIsEdit(true)

    onEdit?.(form, record)
  }

  const handleSubmit = async () => {
    if (!onSubmit) return

    setLoading(true)
    try {
      await onSubmit(form, current, isEdit)

      setOpen(false)
      setCurrent(undefined)
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const columns: TableProps<T>['columns'] = tableProps.columns ? [
    ...tableProps.columns,
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Space>
          {!(typeof hideEdit === 'function' ? hideEdit(record) : hideEdit) && (
            <Tooltip title="Edit">
              <Button icon={<HiOutlinePencil />} onClick={() => handleEdit(record)} />
            </Tooltip>
          )}
          {listActions?.(record)}
        </Space>
      )
    }
  ] : []

  const extra = (
    <Space>
      <Button onClick={handleClose}>Cancel</Button>
      <Button type="primary" onClick={handleSubmit} loading={loading}>
        Submit
      </Button>
    </Space>
  )

  return (
    <>
      <section className="grid grid-rows-[40px_1fr] gap-y-4" style={{ height: 'calc(100vh - 64px)' }}>
        <Row justify="space-between">
          <Col>{headerLeft}</Col>
          <Col>
            <Space>
              {headerActions}
              {!hideAdd && <Button type="primary" size="large" icon={<HiOutlinePlus />} onClick={handleAdd}>{addButtonText ?? 'Add'}</Button>}
            </Space>
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table<T> {...tableProps} columns={columns} />
        </div>
      </section>
      {children ? (
        <Drawer title={formTitle} open={open} width={720} extra={extra} onClose={handleClose}>
          {typeof children === 'function' ? children(form, isEdit) : children}
        </Drawer>
      ) : null}
    </>
  )
}