import Markdown, { Components } from 'react-markdown'
import { Link } from 'react-router-dom'
import remarkGfm from 'remark-gfm'

export default function MarkdownRender({ text }: { text: string }) {
  const components: Partial<Components> = {
    h1({ children }) {
      return <h1 className="mb-6 text-3xl font-b">{children}</h1>
    },
    h3({ children }) {
      return <h3 className="mt-5 text-xl font-b">{children}</h3>
    },
    p({ children }) {
      return <p className="mt-2">{children}</p>
    },
    ul({ children }) {
      return <ul className="mt-6 pl-4.5 list-disc">{children}</ul>
    },
    li({ children }) {
      return <li className="mt-2">{children}</li>
    },
    a({ children, href }) {
      return <Link to={href ?? ''} className="text-primary underline hover:text-primary-hover" target="_blank" rel="noreferrer">{children}</Link>
    },
    strong({ children }) {
      return <strong className="font-b">{children}</strong>
    },
    table({ children }) {
      return <table className="markdown__table">{children}</table>
    }
  }

  return <Markdown remarkPlugins={[remarkGfm]} components={components}>{text}</Markdown>
}