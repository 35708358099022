import PageLink from './PageLink'
import { type Page, PageStatus } from '@/services/page'
import { Badge, Descriptions, type DescriptionsProps } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'

export default function PageExpand({ page }: { page?: Page }) {
  const badgeStatus = useMemo(() => {
    if (page?.status === PageStatus.PUBLISHED) {
      return 'success'
    }

    if (page?.status === PageStatus.FAILED || page?.status === PageStatus.DELETED) {
      return 'error'
    }

    return 'processing'
  }, [page?.status])

  const items: DescriptionsProps['items'] = [
    {
      key: 'pageId',
      label: 'Page ID',
      children: page?.id
    },
    {
      key: 'pageTitle',
      label: 'Page Title',
      children: page && <PageLink id={page.id} status={page.status} title={page.outline?.postTitle} />
    },
    {
      key: 'pageStatus',
      label: 'Page Status',
      children: page && <Badge status={badgeStatus} text={page.status} />
    },
    {
      key: 'pageCoverImage',
      label: 'Page Cover Image',
      children: <img className="max-h-24" src={page?.coverImage} />
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
      children: page && dayjs(page.updatedAt).format('YYYY-MM-DD HH:mm:ss')
    }
  ]

  return <Descriptions layout="vertical" bordered items={items} />
}
