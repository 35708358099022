import { type FormInstance, Form, Input } from 'antd'

const { TextArea } = Input
const FormItem = Form.Item

export default function MarkdownForm({ form, isEdit }: { form: FormInstance<{ markdown: string; personaId: string }>; isEdit: boolean }) {
  return (
    <Form form={form}>
      {isEdit ? (
        <FormItem<{ personaId: string }>
          label="Persona Id:"
          name="personaId"
        >
          <Input />
        </FormItem>
      ) : (
        <FormItem<{ markdown: string }>
          name="markdown"
          rules={[{ required: true, message: 'Please input markdown content' }]}
        >
          <TextArea autoSize={{ minRows: 10 }} />
        </FormItem>
      )}
    </Form>
  )
}