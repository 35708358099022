import Uploader from '@/components/Uploader'
import { searchImage, type ImageSearch } from '@/services/imageSearch'
import { Image as AntdImage, App, Button, Divider, Flex, Input, Table, TableProps } from 'antd'
import { useState } from 'react'
import Box from './components/Box'
import { HiOutlineUpload } from 'react-icons/hi'

export default function ImageSearch() {
  const { message } = App.useApp()
  const [image, setImage] = useState<string>('')
  const [imageList, setImageList] = useState<ImageSearch[]>([])
  const [loading, setLoading] = useState(false)

  const handleSearch = async (val?: string) => {
    const imageUrl = val || image
    if (!imageUrl) {
      return
    }

    setLoading(true)
    try {
      const res = await searchImage(imageUrl, 20)

      if (res) {
        setImageList(res)
      }
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleUploadChange = async (val: string) => {
    setImage(val)

    handleSearch(val)
  }

  const columns: TableProps<ImageSearch>['columns'] = [
    {
      title: 'Box',
      dataIndex: 'bbox',
      render: (bbox: number[]) => <Box image={image} bbox={bbox} />
    },
    {
      title: 'Image',
      dataIndex: 'images',
      render: (images: string[], record: ImageSearch) => (
        <Flex gap={16} wrap>
          {images.map((image, index) => (
            <div className="text-center" key={index}>
              <AntdImage src={image} height={100} />
              <p>{record.score[index]}</p>
            </div>
          ))}
        </Flex>
      )
    }
  ]
  return (
    <section className="overflow-auto" style={{ height: 'calc(100vh - 64px)' }}>
      <Flex align="center" justify="center" gap={16}>
        <Uploader path="image_search" onChange={handleUploadChange}>
          {loading => (
            <Button loading={loading} icon={<HiOutlineUpload />}>
              Upload Image
            </Button>
          )}
        </Uploader>
        <Divider type="vertical" />
        <Flex gap={8}>
          <Input value={image} style={{ width: 300 }} placeholder="Please enter the image url" onPressEnter={() => handleSearch()} onChange={e => setImage(e.target.value)} />
          <Button type="primary" loading={loading} onClick={() => handleSearch()}>Search</Button>
        </Flex>
      </Flex>
      {imageList.length > 0 && (
        <Table
          className="mt-4"
          columns={columns}
          dataSource={imageList}
          showHeader={false}
          loading={loading}
          bordered
          pagination={false}
        />
      )}
    </section>
  )
}
