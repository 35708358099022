import * as ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import * as Sentry from '@sentry/react'
import { App as AntdApp } from 'antd'

dayjs.extend(utc)
dayjs.extend(timezone)

if (import.meta.env.VITE_ENV !== 'local') {
  Sentry.init({
    environment: import.meta.env.VITE_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
  })
}

const container = document.getElementById('app')
if (container) {
  const root = ReactDOM.createRoot(container)

  root.render(
    <BrowserRouter>
      <AntdApp>
        <App />
      </AntdApp>
    </BrowserRouter>
  )
}