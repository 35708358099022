import { App, Skeleton } from 'antd'
import { useCallback, useEffect, useState } from 'react'

export default function MarkdownPreview<T extends { markdown?: string }>({ id, open, fetch }: { id: string; open: boolean; fetch: (id: string) => Promise<T | null> }) {
  const { message } = App.useApp()
  const [pageMarkdown, setPageMarkdown] = useState<T | null>(null)
  const [loading, setLoading] = useState(false)

  const fetchPageMarkdown = useCallback(async () => {
    setLoading(true)
    try {
      const res = await fetch(id)
      if (res) {
        setPageMarkdown(res)
      }
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }, [id, message, fetch])

  useEffect(() => {
    if (id && open) {
      fetchPageMarkdown()
    }
  }, [id, open, fetchPageMarkdown])

  if (loading) {
    return <Skeleton active />
  }

  return (
    <pre className="text-wrap">
      <code>{pageMarkdown?.markdown}</code>
    </pre>
  )
}