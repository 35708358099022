import type { SragParams } from '@/services/srag'
import { Form, Radio, Input, Tabs, type TabsProps, type RadioChangeEvent, Collapse, type CollapseProps, Switch } from 'antd'
import { ChangeEvent, useMemo, useState } from 'react'

const { Item: FormItem } = Form
const { Group: RadioGroup } = Radio
const { TextArea } = Input

const tabItems: TabsProps['items'] = [
  { key: '1', label: 'Intention' },
  { key: '2', label: 'Query Rewrite' },
  { key: '3', label: 'Chat' }
]

export default function DebugForm({ value, gateway, onChange, onGatewayChange }: { value: SragParams; gateway: string; onChange: (val: SragParams) => void; onGatewayChange: (val: string) => void }) {
  const [promptTab, setPromptTab] = useState('1')
  
  const activeTab = useMemo(() => tabItems?.find(v => v.key === promptTab), [promptTab])
  const promptPlaceholder = useMemo(() => activeTab ? `Enter your ${activeTab.label} prompt here` : '', [activeTab])
  const promptValue = useMemo(() => {
    const values: { [k: number]: string } = {
      1: value.intentionPrompt,
      2: value.queryRewritePrompt,
      3: value.chatPrompt
    }

    return activeTab?.key ? values[parseInt(activeTab.key)] ?? '' : ''
  }, [value, activeTab])

  const handleChange = (val: Partial<SragParams>) => {
    onChange({ ...value, ...val })
  }

  const handleModelChange = (val: RadioChangeEvent) => {
    onChange({ ...value, model: val.target.value })
  }

  const handlePromptTabChange = (val: string) => {
    setPromptTab(val)
  }

  const handlePromptChange = (val: ChangeEvent<HTMLTextAreaElement>) => {
    const prompt = val.target.value
    switch (activeTab?.key) {
      case '1':
        onChange({ ...value, intentionPrompt: prompt })
        return
      case '2':
        onChange({ ...value, queryRewritePrompt: prompt })
        return
      case '3':
        onChange({ ...value, chatPrompt: prompt })
        return
      default:
        return
    }
  }

  const handleGatewayChange = (e: ChangeEvent<HTMLInputElement>) => {
    onGatewayChange(e.target.value)
  }

  const collapseItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Prompt:',
      children: (
        <>
          <Tabs size="small" items={tabItems} onChange={handlePromptTabChange} />
          <TextArea value={promptValue} placeholder={promptPlaceholder} rows={12} onChange={handlePromptChange} />
        </>
      )
    },
    {
      key: '2',
      label: 'Local Debug Gateway',
      children: <Input value={gateway} onChange={handleGatewayChange} />
    }
  ]

  return (
    <Form labelAlign="left" labelCol={{ span: 7 }}>
      <FormItem label="Debug Level:">
        <RadioGroup value={value.debugLevel} onChange={val => handleChange({ debugLevel: val.target.value })}>
          <Radio value={0}>Level 0</Radio>
          <Radio value={1}>Level 1</Radio>
          <Radio value={2}>Level 2</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="Model:">
        <RadioGroup value={value.model} onChange={handleModelChange}>
          <Radio value="openai/gpt-4o-mini">GPT-4o mini</Radio>
          <Radio value="bedrock/anthropic.claude-3-haiku-20240307-v1:0">Claude 3 Haiku</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="NER">
        <Switch checked={value.useNer} onChange={val => handleChange({ useNer: val })} />
      </FormItem>
      <FormItem label="Query Rewrite">
        <Switch checked={value.useQueryRewrite} onChange={val => handleChange({ useQueryRewrite: val })} />
      </FormItem>
      <FormItem label="Intention">
        <Switch checked={value.intentionEnable} onChange={val => handleChange({ intentionEnable: val })} />
      </FormItem>
      <div className="-mx-3 mb-6">
        <Collapse size="small" items={collapseItems} ghost />
      </div>
    </Form>
  )
}