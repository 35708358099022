import readFile from '@/utils/readFile'
import { App, Button, Upload, type UploadProps } from 'antd'
import { HiOutlineUpload } from 'react-icons/hi'
import { createTrendBrief } from '@/services/trendPage'

export default function UploadTrendsFile({ onSuccess }: { onSuccess: () => void }) {
  const { message, modal } = App.useApp()

  const handleConfirm = async (trends: { sourceType: string; trend: string }[], filename: string) => {
    if (!trends.length) {
      return
    }

    try {
      await Promise.all(trends.map(item => createTrendBrief(item.trend, filename, item.sourceType)))

      onSuccess()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    }
  }

  const handleBeforeUpload: UploadProps['beforeUpload'] = async file => {
    try {
      const filename = file.name.split('.').slice(0, -1).join('.')

      const data = await readFile(file)

      const arr = data.split('\r\n').slice(1).map(item => {
        const [sourceType, ...trend] = item.split(',')

        return {
          sourceType,
          trend: trend.filter(Boolean).join('\n')
        }
      })

      modal.confirm({
        title: 'Upload Trends',
        content: (
          <div>
            <p className="mb-4">Are you sure you want to create briefs with these trends?</p>
            <ul className="list-disc list-inside">
              {arr.map((item, index) => (
                <li key={index}>{item.sourceType} - {item.trend}</li>
              ))}
            </ul>
          </div>
        ),
        onOk: () => handleConfirm(arr, filename),
      })
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    }

    return false
  }

  return (
    <Upload accept=".csv" showUploadList={false} beforeUpload={handleBeforeUpload}>
      <Button icon={<HiOutlineUpload />} size="large">Upload Trends</Button>
    </Upload>
  )
}

