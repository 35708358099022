import { useCallback, useEffect, useRef } from 'react'

export default function Box({ image, bbox }: { image: string; bbox: number[] }) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const size = useRef<{ width: number; height: number }>({ width: 0, height: 0 })

  const drawImage = useCallback(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    const img = new Image()
    img.onload = () => {
      size.current.width = img.width
      size.current.height = img.height

      const containerHeight = 300
      const containerWidth = containerHeight * img.width / img.height

      canvas.width = containerWidth
      canvas.height = containerHeight

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
    
      ctx.strokeStyle = 'red'
      ctx.lineWidth = 2

      const scaleX = canvas.width / size.current.width
      const scaleY = canvas.height / size.current.height

      ctx.strokeRect(bbox[0] * scaleX, bbox[1] * scaleY, (bbox[2] - bbox[0]) * scaleX, (bbox[3] - bbox[1]) * scaleY)
    }
    img.src = image
  }, [image, bbox])

  useEffect(() => {
    drawImage()
  }, [drawImage])

  return <canvas ref={canvasRef} />
}
