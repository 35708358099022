import TableList, { type TableListProps } from '@/components/TableList'
import useTable from '@/hooks/useTable'
import {
  type TrendPage,
  TrendPageStatus,
  batchTrendPages,
  batchRetryTrendPages,
  queryTrendPage,
  queryTrendPages
} from '@/services/trendPage'
import { Drawer, Space, Tag, type TableProps } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { HiCheckCircle, HiOutlineClock, HiXCircle } from 'react-icons/hi'
import { AiOutlineSync } from 'react-icons/ai'
import BriefForm from './components/BriefForm'
import MarkdownPreview from './components/MarkdownPreview'
import PageExpand from './components/PageExpand'
import UploadTrendsFile from './components/UploadTrendsFile'
import BatchButtons from './components/BatchButtons'
import TrendListButton from './components/TrendListButton'
import Filters from './components/Filters'
import { Page } from '@/services/page'

const TrendPageStatusMap = {
  [TrendPageStatus.CreatingBrief]: {
    color: 'processing',
    icon: (
      <div className="animate-spin">
        <AiOutlineSync />
      </div>
    )
  },
  [TrendPageStatus.Pending]: {
    color: 'default',
    icon: <HiOutlineClock />
  },
  [TrendPageStatus.Success]: {
    color: 'success',
    icon: <HiCheckCircle />
  },
  [TrendPageStatus.Failed]: {
    color: 'error',
    icon: <HiXCircle />
  },
  [TrendPageStatus.Processing]: {
    color: 'processing',
    icon: (
      <div className="animate-spin">
        <AiOutlineSync />
      </div>
    )
  }
}

export default function TrendToPage() {
  const [pages, setPages] = useState<{ [k: string]: Page }>({})
  const [expanded, setExpanded] = useState<string[]>([])
  const [filename, setFilename] = useState('')
  const [previewId, setPreviewId] = useState('')
  const [selectedTrends, setSelectedTrends] = useState<TrendPage[]>([])
  const [open, setOpen] = useState(false)

  const { tableProps, changeSearch, fetchList, searchParams } = useTable(
    useCallback(async (page, pageSize, search) => {
      const { list, total, pages } = await queryTrendPages(page, pageSize, search.get('status') ?? undefined, search.get('filename') ?? undefined)

      setPages(Object.fromEntries(pages.map(page => [page.id, page])))
      setExpanded(list.filter(record => record.status === TrendPageStatus.Success).map(record => record.id))

      return { list, total, pages }
    }, [])
  )

  const batchIds = useMemo(() => ({
    process: selectedTrends.filter(record => record?.status === TrendPageStatus.Pending).map(record => record.id),
    retry: selectedTrends.filter(record => record?.status === TrendPageStatus.Failed).map(record => record.id),
    success: selectedTrends.filter(record => record?.status === TrendPageStatus.Success).map(record => record.pageId).filter(Boolean)
  }), [selectedTrends])

  const handleFilenameSearch = () => {
    changeSearch({ filename, status: searchParams.get('status') ?? '' })
  }

  const handleOpenMarkdownDrawer = (id: string) => {
    setPreviewId(id)
    setOpen(true)
  }

  const handleSuccess = () => {
    setOpen(false)

    fetchList()
  }

  const handleBatchSuccess = () => {
    fetchList()
    setSelectedTrends([])
  }

  const handleStatusChange = (value: TrendPageStatus) => {
    changeSearch({ status: value ?? '' })
  }

  useEffect(() => {
    const params = searchParams.get('filename')
    if (params) {
      setFilename(params)
    }
  }, [searchParams])

  const columns: TableProps<TrendPage>['columns'] = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Trend',
      key: 'trend',
      dataIndex: 'trend',
      width: 300
    },
    {
      title: 'Brief',
      key: 'brief',
      dataIndex: 'brief',
      width: 800
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (value: TrendPageStatus) => (
        <Tag className="inline-flex items-center gap-x-1" icon={TrendPageStatusMap[value].icon} color={TrendPageStatusMap[value].color}>
          {value}
        </Tag>
      )
    },
    {
      title: 'Updated At',
      key: 'updated_at',
      dataIndex: 'updatedAt',
      render: value => (
        <p className="text-nowrap">{dayjs.utc(value).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')}</p>
      )
    }
  ]

  const tableListProps: TableListProps<TrendPage> = {
    tableProps: {
      ...tableProps,
      columns,
      scroll: {
        x: 'max-content'
      },
      rowSelection: {
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedTrends.map(record => record.id),
        getCheckboxProps: record => ({
          disabled: record.status === TrendPageStatus.CreatingBrief || record.status === TrendPageStatus.Processing
        }),
        onChange: (_, selectedRows) => {
          setSelectedTrends(selectedRows)
        }
      },
      expandable: {
        expandedRowKeys: expanded,
        rowExpandable: record => record.status === TrendPageStatus.Success,
        expandedRowRender: record => record.pageId && pages[record.pageId] && <PageExpand page={pages[record.pageId]} />,
        onExpand: (expanded, record) => {
          setExpanded(prev => {
            if (expanded) {
              return [...prev, record.id]
            } else {
              return prev.filter(id => id !== record.id)
            }
          })
        }
      }
    },
    addButtonText: 'Add Trend',
    hideEdit: true,
    listActions: record => (
      <TrendListButton
        page={record}
        onPreview={handleOpenMarkdownDrawer}
        onSuccess={fetchList}
      />
    ),
    headerLeft: (
      <Filters
        searchParams={searchParams}
        options={Object.entries(TrendPageStatus)}
        showFilenameSearch
        onStatusChange={handleStatusChange}
        onFilenameChange={handleFilenameSearch}
      />
    ),
    headerActions: (
      <Space>
        <BatchButtons
          batchRetryIds={batchIds.retry}
          batchProcessIds={batchIds.process}
          batchSuccessIds={batchIds.success}
          batchRetry={batchRetryTrendPages}
          batchProcess={batchTrendPages}
          onSuccess={handleBatchSuccess}
        />
        <UploadTrendsFile onSuccess={fetchList} />
      </Space>
    ),
    onAdd: () => {
      setPreviewId('')
      setOpen(true)
    }
  }

  return (
    <>
      <TableList {...tableListProps} />
      <Drawer title={previewId ? 'Markdown Preview' : 'Start Pipeline'} width={720} open={open} maskClosable={!!previewId} destroyOnClose onClose={() => setOpen(false)}>
        {previewId ? <MarkdownPreview id={previewId} open={open} fetch={queryTrendPage} />: <BriefForm onSuccess={handleSuccess} />}
      </Drawer>
    </>
  )
}