import { newRequest } from '@/utils/request'

export interface ImageSearch {
  images: string[]
  bbox: number[]
  score: string[]
}

const request = newRequest(import.meta.env.VITE_FAVIE_GATEWAY_HOST)

export async function searchImage(imageUrl: string, topK?: number) {
  const res = await request.get<{ image_list: { image_url: string[]; bbox: number[]; score: string[] }[] }>('/image_search', {
    image_url: imageUrl,
    topk: topK
  })

  return res?.image_list.map<ImageSearch>(({ image_url, bbox, score }) => ({
    images: image_url,
    bbox,
    score
  }))
}
