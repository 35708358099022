import request from '@/utils/request'

export enum PageStatus {
  PREWRITING = 'prewriting',
  WRITING = 'writing',
  PREVIEW = 'preview',
  PUBLISHED = 'published',
  EDITING = 'editing',
  DRAFT = 'draft',
  DELETED = 'deleted',
  FAILED = 'failed'
}

interface Outline {
  postTitle: string
  postSubtitle: string
  intro: string
  body: { chapterTitle: string }[]
  persona?: { id: string }
}

interface Chapter {
  content: {
    chapterTitle: string
    content: string
    productList: { productQuery: string }[]
  }
}

export interface PageResponse {
  _id: string
  status: PageStatus
  cover_image: string
  outline?: {
    post_title: string
    post_subtitle: string
    intro: string
    body: { chapter_title: string }[]
  }
  created_at: string
  updated_at: string
}

export interface Page {
  id: string
  status: PageStatus
  coverImage: string
  outline: Outline
  createdAt: string
  updatedAt: string
}

export interface PageData {
  outline: Outline
  chapters: Chapter[]
  personaId: string
}

export const queryPages = async (page: number, pageSize: number) => {
  const res = await request.get<{ data: PageResponse[]; total: number }>('/pages', { page: page.toString(), page_size: pageSize.toString() })

  const list = (res?.data ?? []).map<Page>(v => ({
    id: v._id,
    status: v.status,
    coverImage: v.cover_image,
    outline: {
      postTitle: v.outline?.post_title ?? '',
      postSubtitle: v.outline?.post_subtitle ?? '',
      intro: v.outline?.intro ?? '',
      body: v.outline?.body?.map(v => ({ chapterTitle: v.chapter_title })) ?? []
    },
    createdAt: v.created_at,
    updatedAt: v.updated_at
  }))

  return { list, total: res?.total ?? 0 }
}

export const queryPage = async (pageId: string): Promise<Page | null> => {
  const res = await request.get<{ page: PageResponse }>(`/pages/${pageId}`)
  if (!res?.page) {
    return null
  }

  return {
    id: res.page._id,
    status: res.page.status,
    coverImage: res.page.cover_image,
    outline: {
      postTitle: res.page.outline?.post_title ?? '',
      postSubtitle: res.page.outline?.post_subtitle ?? '',
      intro: res.page.outline?.intro ?? '',
      body: res.page.outline?.body?.map(v => ({ chapterTitle: v.chapter_title })) ?? []
    },
    createdAt: res.page.created_at,
    updatedAt: res.page.updated_at
  }
}

export const createPage = async (data: PageData) => {
  return request.post('/pages', {
    outline: {
      post_title: data.outline.postTitle,
      post_subtitle: data.outline.postSubtitle,
      intro: data.outline.intro,
      body: data.outline.body.map(v => ({ chapter_title: v.chapterTitle }))
    },
    chapters: data.chapters.map(v => ({
      content: {
        chapter_title: v.content.chapterTitle,
        content: v.content.content,
        product_list: v.content.productList.map(v => ({ product_query: v.productQuery }))
      }
    })),
    persona_id: data.personaId.replace(/^0+/, '')
  })
}

export const updatePagePersona = async (pageId: string, data: { personId: string; outline: Outline }) => {
  return request.post(`/pages/${pageId}`, {
    outline: {
      post_title: data.outline.postTitle,
      post_subtitle: data.outline.postSubtitle,
      intro: data.outline.intro,
      body: data.outline.body.map(v => ({ chapter_title: v.chapterTitle }))
    },
    persona_id: data.personId.replace(/^0+/, '')
  })
}

export const updatePageStatus = async (pageId: string, status: string) => {
  return request.post(`/pages/${pageId}/status`, { status })
}

export const batchUpdatePageStatus = async (pageIds: string[], status: string) => {
  return request.post('/batch/page_status', { page_ids: pageIds, status })
}

export const batchCreatePage = async (data: PageData[]) => {
  const pages = data.map(v => ({
    outline: {
      post_title: v.outline.postTitle,
      post_subtitle: v.outline.postSubtitle,
      intro: v.outline.intro,
      body: v.outline.body.map(j => ({ chapter_title: j.chapterTitle }))
    },
    chapters: v.chapters.map(_v => ({
      content: {
        chapter_title: _v.content.chapterTitle,
        content: _v.content.content,
        product_list: _v.content.productList.map(j => ({ product_query: j.productQuery }))
      }
    })),
    persona_id: v.personaId.replace(/^0+/, '')
  }))

  return request.post('/batch/pages', pages)
}

export const batchPublishPage = async (ids: string[]) => {
  return request.post('/batch/page_publish', ids)
}
