import { type AgentPrompt, type AgentData } from '@/services/agent'
import { Form, Input, type FormInstance } from 'antd'
import Prompts from './Prompts'

const FormItem = Form.Item
const TextArea = Input.TextArea

export default function ItemForm({ form, prompts, isEdit, onPromptsChange }: { form: FormInstance<AgentData>; prompts: AgentPrompt[]; isEdit?: boolean; onPromptsChange: (value: AgentPrompt[]) => void }) {
  return (
    <Form form={form} layout="vertical">
      <FormItem name="name" label="Name" rules={[{ required: true, message: 'Please enter the name' }]}>
        <Input disabled={isEdit} />
      </FormItem>
      <FormItem name="description" label="Description">
        <TextArea rows={8} />
      </FormItem>
      <FormItem label="Prompts">
        <Prompts value={prompts} onChange={onPromptsChange} />
      </FormItem>
    </Form>
  )
}