import { createPersona, deletePersona, type PersonaData, queryPersonas, updatePersona, type Persona } from '@/services/persona'
import { App, Button, Descriptions, FormInstance, Popconfirm, Tooltip, type TableProps } from 'antd'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import ItemForm from './components/ItemForm'
import TableList, { type TableListProps } from '@/components/TableList'
import { HiOutlineTrash } from 'react-icons/hi'
import useTable from '@/hooks/useTable'

const DescriptionItem = Descriptions.Item

export default function Personas() {
  const { message } = App.useApp()
  const { tableProps, list, fetchList } = useTable(
    useCallback((page, pageSize) => queryPersonas(page, pageSize), [])
  )

  const handleAdd = (form: FormInstance<PersonaData>) => {
    form.setFieldsValue({
      personaId: list.length ? list.length + 1 : 1,
    })
  }

  const handleSubmit = async (form: FormInstance<PersonaData>, record?: Persona, isEdit?: boolean) => {
    const values = await form.validateFields()
    if (isEdit) {
      if (!record) {
        return
      }

      await updatePersona(record.id, values)
    } else {
      await createPersona(values)
    }

    fetchList()
  }

  const handleDelete = async (id: string) => {
    try {
      await deletePersona(id)

      fetchList()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    }
  }

  const columns: TableProps<Persona>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Persona ID',
      dataIndex: 'personaId',
      key: 'personaId'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: src => <img src={src} alt="avatar" className="w-16" />
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: date => dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    }
  ]

  const renderExpandedRow = (record: Persona) => {
    return (
      <Descriptions bordered>
        <DescriptionItem label="Bio">{record.bio}</DescriptionItem>
        <DescriptionItem label="Introduction">{record.intro}</DescriptionItem>
        <DescriptionItem label="Background">{record.background}</DescriptionItem>
        <DescriptionItem label="Interest">{record.interest}</DescriptionItem>
        <DescriptionItem label="Audience Info">{record.audienceInfo}</DescriptionItem>
        <DescriptionItem label="Content Style">{record.contentStyle}</DescriptionItem>
        <DescriptionItem label="Persona Doc">{record.personaDoc}</DescriptionItem>
      </Descriptions>
    )
  }

  const props: TableListProps<Persona, PersonaData> = {
    tableProps: {
      ...tableProps,
      columns,
      expandable: {
        expandedRowRender: renderExpandedRow
      }
    },
    formTitle: 'Persona',
    listActions: record => (
      <Popconfirm title="Are you sure you want to delete this persona?" onConfirm={() => handleDelete(record.id)}>
        <Tooltip title="Delete">
          <Button type="primary" danger icon={<HiOutlineTrash />} />
        </Tooltip>
      </Popconfirm>
    ),
    onAdd: handleAdd,
    onSubmit: handleSubmit
  }

  return (
    <TableList {...props}>
      {form => <ItemForm form={form} />}
    </TableList>
  )
}