import request from '@/utils/request'

export interface Prompt {
  id: string
  name: string
  prompt: string
  description?: string
  operator: string
  updatedAt: string
}

export type PromptResponse = Pick<Prompt, 'name' | 'prompt' | 'description' | 'operator'> & {
  _id: string
  updated_at: string
}

export const queryPrompts = async (page: number, pageSize: number, name?: string) => {
  const res = await request.get<{ data: PromptResponse[]; total: number }>('/prompts', {
    page: page.toString(),
    page_size: pageSize.toString(),
    name: name ?? ''
  })

  const list = (res?.data ?? []).map<Prompt>(v => ({
    id: v._id,
    name: v.name,
    prompt: v.prompt,
    description: v.description,
    operator: v.operator,
    updatedAt: v.updated_at
  }))

  return { list, total: res?.total ?? 0 }
}

export const createPrompt = (data: Pick<Prompt, 'name' | 'prompt' | 'description'>) => {
  return request.post('/prompts', data)
}

export const updatePrompt = (id: string, data: Pick<Prompt, 'name' | 'prompt' | 'description'>) => {
  return request.post(`/prompts/${id}`, data)
}

export const deletePrompt = (id: string) => {
  return request.post(`/prompts/${id}/delete`)
}

export const applyPrompt = (id: string) => {
  return request.post(`/prompts/${id}/apply`)
}
