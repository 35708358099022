import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './app/Home'
import SragAsk from './app/SragAsk'
import RecommendQuestions from './app/RecommendQuestions'
import Favicons from './app/Favicons'
import Personas from './app/Personas'
import Prompts from './app/Prompts'
import Agents from './app/Agents'
import MarkdownToPage from './app/Pages/MarkdownToPage'
import TrendToPage from './app/Pages/TrendToPage'
import YoutubeToPage from './app/Pages/YoutubeToPage'
import StealStyle from './app/StealStyle'
import ImageSearch from './app/ImageSearch'
import { useCallback, useEffect, useState } from 'react'
import { Flex, Spin, App as AntdApp } from 'antd'
import request from './utils/request'
import Welcome from './app/Welcome'

const isLocal = import.meta.env.VITE_ENV === 'local'

const App = () => {
  const { message } = AntdApp.useApp()
  const [loading, setLoading] = useState(!isLocal)
  const [isSrp, setIsSrp] = useState(isLocal)
  const [inWhitelist, setInWhitelist] = useState(false)

  const queryWhitelist = useCallback(async () => {
    try {
      const res = await request.get<{ is_srp: boolean; in_whitelist: boolean }>('/auth')

      setIsSrp(!!res?.is_srp)
      setInWhitelist(!!res?.in_whitelist)
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }, [message])

  useEffect(() => {
    if (!isLocal) {
      queryWhitelist()
    }
  }, [queryWhitelist])

  if (loading) {
    return (
      <Flex justify="center" align="center" style={{ height: '100vh' }}>
        <Spin spinning />
      </Flex>
    )
  }

  if (!inWhitelist && !isSrp) {
    return null
  }

  return (
    <Routes>
      <Route element={<Layout inWhitelist={inWhitelist} isSrp={isSrp} />}>
        <Route path="/" index element={inWhitelist ? <Welcome /> : <Home />} />
        {isSrp && (
          <>
            <Route path="/srag_ask" element={<SragAsk />} />
            <Route path="/recommend_questions" element={<RecommendQuestions />} />
            <Route path="/favicons" element={<Favicons />} />
            <Route path="/personas" element={<Personas />} />
            <Route path="/prompts" element={<Prompts />} />
            <Route path="/agents" element={<Agents />} />
            <Route path="/pages" element={<MarkdownToPage />} />
            <Route path="/trend_to_pages" element={<TrendToPage />} />
            <Route path="/youtube_to_page" element={<YoutubeToPage />} />
            <Route path="/image_search" element={<ImageSearch />} />
          </>
        )}
        <Route path="/steal_style" element={<StealStyle />} />
      </Route>
    </Routes>
  )
}

export default App