import { SheetRow } from '@/services/sheets'
import { createContext, useContext } from 'react'

interface ContextParams {
  rows: SheetRow[]
  active: string
  loading: boolean
}

export const NavigationContext = createContext<ContextParams | undefined>(undefined)

export default function useNavigation() {
  const context = useContext(NavigationContext)
  if (!context) {
    throw new Error('context must be used within provider')
  }

  return context
}
