import Uploader from '@/components/Uploader'
import type { FaviconData } from '@/services/favicon'
import { Col, Form, FormInstance, Input, Radio, type RadioChangeEvent, Row } from 'antd'
import { useState } from 'react'

const { Item: FormItem } = Form
const { Group: RadioGroup, Button: RadioButton } = Radio

export default function ItemForm({ form, isEdit }: { form: FormInstance<Pick<FaviconData, 'domain' | 'originUrl'>>; isEdit?: boolean }) {
  const originUrl = Form.useWatch('originUrl', form)
  const [showUploader, setShowUploader] = useState(false)

  const handleTypeChange = (e: RadioChangeEvent) => {
    setShowUploader(e.target.value === 'upload')
  }

  const handleUploadChange = (val: string) => {
    form.setFieldValue('originUrl', val)
  }

  return (
    <Form form={form} layout="vertical">
      <FormItem<FaviconData>
        label="Domain:"
        name="domain"
        rules={[{ required: true, message: 'Please input domain' }]}
      >
        <Input disabled={isEdit} />
      </FormItem>
      <FormItem>
        <RadioGroup buttonStyle="solid" value={showUploader ? 'upload' : 'input'} onChange={handleTypeChange}>
          <RadioButton value="input">Input URL</RadioButton>
          <RadioButton value="upload">Upload Image</RadioButton>
        </RadioGroup>
      </FormItem>
      <FormItem<FaviconData>
        name="originUrl" 
        rules={[{ required: true, message: 'Please input origin url' }]}
      >
        <Row gutter={20} align="middle">
          {!!originUrl && (
            <Col>
              <img src={originUrl} className="w-16" />
            </Col>
          )}
          <Col flex="auto">
            {showUploader ? <Uploader path="favicons" onChange={handleUploadChange} /> : <Input value={originUrl} />}
          </Col>
        </Row>
      </FormItem>
    </Form>
  )
}