import { processYoutubePage, retryYoutubePage, YoutubePageStatus, type YoutubePage } from '@/services/youtubePage'
import { App, Button, Space, Tooltip } from 'antd'
import { useState } from 'react'
import { AiOutlineReload } from 'react-icons/ai'
import { HiOutlineDocument, HiOutlinePlay } from 'react-icons/hi2'

export default function YoutubeListButton({ page, onSuccess, onPreview }: { page: YoutubePage; onSuccess: () => void; onPreview: (id: string) => void }) {
  const { message } = App.useApp()
  const [loading, setLoading] = useState(false)

  const handleProcess = async () => {
    setLoading(true)
    try {
      await processYoutubePage(page.id)

      onSuccess()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleRetry = async () => {
    setLoading(true)
    try {
      await retryYoutubePage(page.id)

      onSuccess()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handlePreview = () => {
    onPreview(page.id)
  }

  return (
    <Space>
      <Tooltip title="View Markdown">
        <Button icon={<HiOutlineDocument />} onClick={handlePreview} />
      </Tooltip>
      {page.status === YoutubePageStatus.Pending && (
        <Tooltip title="Create Page">
          <Button loading={loading} type="primary" icon={<HiOutlinePlay />} onClick={handleProcess} />
        </Tooltip>
      )}
      {page.status === YoutubePageStatus.Failed && (
        <Tooltip title="Retry Create Page">
          <Button loading={loading} type="primary" danger icon={<AiOutlineReload />} onClick={handleRetry} />
        </Tooltip>
      )}
    </Space>
  )
}
