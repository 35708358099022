import { PageStatus } from '@/services/page'
import { HiExternalLink } from 'react-icons/hi'
import { Link } from 'react-router-dom'

export default function PageLink({ id, title, status }: { id: string; title: string; status: PageStatus }) {
  return (
    <Link className="text-nowrap" to={`${import.meta.env.VITE_FAVIE_HOST}/page${status === PageStatus.PUBLISHED ? `/${id}` : `?id=${id}`}`} target="_blank">
      <HiExternalLink className="inline-block align-middle" />
      <span className="inline-block align-middle ml-1 text-wrap">{title}</span>
    </Link>
  )
}