import { queryPrompts } from '@/services/prompt'
import { Select, Spin, App } from 'antd'
import { useState } from 'react'
import debounce from '@/utils/debounce'

export default function PromptSearch({ value, onChange }: { value?: { promptId: string; promptName: string }; onChange?: (value: { promptId: string; promptName: string }) => void }) {
  const { message } = App.useApp()
  const [options, setOptions] = useState<{ label: string; value: string }[]>([])
  const [loading, setLoading] = useState(false)

  const handleSearch = debounce(async (value: string) => {
    if (value.trim() === '') {
      setOptions([])
      return
    }

    setLoading(true)
    try {
      const res = await queryPrompts(1, 10, value)

      setOptions(res.list.map(v => ({ label: v.name, value: v.id })))
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
      setOptions([])
    } finally {
      setLoading(false)
    }
  }, 300)

  const handleSelect = (value: { label: string; value: string }) => {
    onChange?.({ promptId: value.value, promptName: value.label })
  }

  return (
    <Select
      value={{ label: value?.promptName ?? '', value: value?.promptId ?? '' }}
      placeholder="Select Prompt"
      labelInValue
      showSearch
      options={options}
      filterOption={false}
      notFoundContent={loading ? <Spin size="small" /> : null}
      onSearch={handleSearch}
      onSelect={handleSelect}
    />
  )
}