export async function stream<T>(url: string, body: T) {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })

  if (res.status >= 400) {
    throw new Error(`${res.status} ${res.statusText}`)
  }

  if (!res.body) {
    throw new Error('no response body')
  }

  return res.body.getReader()
}

export function newRequest(baseUrl = `${import.meta.env.VITE_DASHBOARD_GATEWAY_HOST}/v1`) {
  const send = async <T>(uri: string, options: RequestInit) => {
    const res = await fetch(`${baseUrl}${uri}`, import.meta.env.VITE_ENV === 'local' ? {
      ...options,
      headers: {
        ...options.headers,
        Authorization: window.localStorage.getItem('CF_Authorization') ?? ''
      }
    } : options)

    if (res.status >= 400) {
      const json = await res.json() as { detail: string | object }

      throw new Error(typeof json.detail === 'string' ? json.detail : `${res.status} ${res.statusText}`)
    }

    if (res.status === 204) {
      return null
    }

    if (!res.body) {
      throw new Error('no response')
    }

    return res.json() as T
  }

  const get = <T>(uri: string, params?: Record<string, string | number | undefined>) => {
    const searchParams = Object.entries(params ?? {}).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc.append(key, value.toString())
      }

      return acc
    }, new URLSearchParams())

    return send<T>(`${uri}${params ? `?${searchParams.toString()}` : ''}`, { method: 'GET' })
  }

  const post = <T, P>(uri: string, body?: P) => send<T>(uri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: body && JSON.stringify(body)
  })

  const postForm = <T>(uri: string, body?: FormData) => send<T>(uri, {
    method: 'POST',
    body
  })

  return { get, post, postForm }
}

const request = newRequest()

export default request