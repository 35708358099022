import { type Prompt } from '@/services/prompt'
import { type FormInstance, Form, Input } from 'antd'

const FormItem = Form.Item
const TextArea = Input.TextArea

export default function ItemForm({ form, isEdit }: { form: FormInstance<Pick<Prompt, 'name' | 'prompt' | 'description'>>; isEdit?: boolean }) {
  return (
    <Form form={form} layout="vertical">
      <FormItem name="name" label="Name" rules={[{ required: true, message: 'Please enter the name' }]}>
        <Input disabled={isEdit} />
      </FormItem>
      <FormItem name="prompt" label="Prompt" rules={[{ required: true, message: 'Please enter the prompt' }]}>
        <TextArea autoSize={{ minRows: 8 }} />
      </FormItem>
      <FormItem name="description" label="Description">
        <TextArea autoSize={{ minRows: 8 }} />
      </FormItem>
    </Form>
  )
}