import request from '@/utils/request'

export interface AgentPrompt {
  key: string
  promptId: string
  promptName: string
}

export interface Agent {
  id: string
  name: string
  description: string
  prompts: {
    [key: string]: {
      promptId: string
      promptName: string
      prompt?: string
    }
  }
  updatedAt: string
}

export interface AgentResponse {
  id: string
  name: string
  description: string
  prompts: {
    [key: string]: {
      prompt_id: string
      prompt_name: string
    }
  }
  updated_at: string
}

export type AgentData = Omit<Agent, 'id' | 'updatedAt'>

export async function queryAgents(page: number, pageSize: number, name?: string) {
  const res = await request.get<{ data: AgentResponse[]; total: number }>('/agents', {
    page,
    page_size: pageSize,
    name
  })

  const list = (res?.data || []).map<Omit<Agent, 'prompts'>>(item => ({
    id: item.id,
    name: item.name,
    description: item.description,
    updatedAt: item.updated_at,
  }))

  return { list, total: res?.total || 0 }
}

export async function queryAgent(id: string) {
  const res = await request.get<AgentResponse>(`/agents/${id}`)
  if (!res) {
    return null
  }

  return {
    id: res.id,
    name: res.name,
    description: res.description,
    updatedAt: res.updated_at,
    prompts: Object.entries(res.prompts).reduce<Agent['prompts']>((acc, [key, value]) => ({
      ...acc,
      [key]: { promptId: value.prompt_id, promptName: value.prompt_name },
    }), {}),
  }
}

export function createAgent(data: AgentData) {
  return request.post('/agents', {
    name: data.name,
    description: data.description,
    prompts: Object.entries(data.prompts).reduce<AgentResponse['prompts']>((acc, [key, value]) => ({
      ...acc,
      [key]: { prompt_id: value.promptId, prompt_name: value.promptName },
    }), {}),
  })
}

export function updateAgent(id: string, data: AgentData) {
  return request.post(`/agents/${id}`, {
    name: data.name,
    description: data.description,
    prompts: Object.entries(data.prompts).reduce<AgentResponse['prompts']>((acc, [key, value]) => ({
      ...acc,
      [key]: { prompt_id: value.promptId, prompt_name: value.promptName },
    }), {}),
  })
}

export function addPromptToAgent(agentId: string, promptKey: string, promptId: string) {
  return request.post(`/agents/${agentId}/prompts`, {
    prompt_key: promptKey,
    prompt_id: promptId,
  })
}
