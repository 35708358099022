import { type Page, PageStatus, batchUpdatePageStatus } from '@/services/page'
import UploadMarkdownFiles from './UploadMarkdownFiles'
import { App, Button, Space } from 'antd'
import { HiOutlineEye } from 'react-icons/hi'
import { useMemo } from 'react'

export default function ListHeaderButtons({ selectedRows, onSuccess }: { selectedRows: Page[]; onSuccess: () => void }) {
  const { message, modal } = App.useApp()

  const pages = useMemo(() => {
    return selectedRows.filter(row => ![PageStatus.DELETED, PageStatus.FAILED, PageStatus.PUBLISHED].includes(row.status)).map(row => row.id)
  }, [selectedRows])

  const handleBatchPublish = () => {
    modal.confirm({
      title: 'Batch Publish',
      content: 'Are you sure you want to publish these pages?',
      onOk: async () => {
        if (!pages.length) {
          return
        }

        try {
          await batchUpdatePageStatus(pages, PageStatus.PUBLISHED)

          onSuccess()
        } catch (error) {
          if (error instanceof Error) {
            message.error(error.message)
          }
        }
      }
    })
  }

  return (
    <Space>
      <Button size="large" icon={<HiOutlineEye />} disabled={pages.length === 0} onClick={handleBatchPublish}>
        Batch Publish
      </Button>
      <UploadMarkdownFiles onUploaded={onSuccess} />
    </Space>
  )
}