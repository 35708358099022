import { StealStyleQuery, type Shopping } from '@/services/stealStyle'
import { Image } from 'antd'
import ShoppingItem from './ShoppingItem'

export default function DressCode({ queries, shopping }: { queries: StealStyleQuery[]; shopping: { [key: string]: Shopping } }) {
  const top = shopping['top']
  const bottom = shopping['bottom']
  const shoes = shopping['shoes']
  const bag = shopping['bag']
  const accessories = shopping['accessories']
  const glasses = shopping['glasses']

  return (
    <div className="flex gap-4 mb-4">
      <div className="grid grid-rows-2 gap-y-4">
        {top && (
          <div>
            <div className="flex flex-col items-start overflow-hidden w-40 h-40">
              <Image src={top.thumbnail} preview={{
                destroyOnClose: true,
                imageRender: () => <ShoppingItem queries={queries.map(query => query['top'] ?? '')} shopping={top} />,
                toolbarRender: () => null
              }} />
            </div>
            <p className="mt-2 text-sm line-clamp-2">{queries[0]['top']}</p>
          </div>
        )}
        {bottom && (
          <div>
            <div className="flex items-center overflow-hidden w-40 h-40">
              <Image src={bottom.thumbnail} preview={{
                destroyOnClose: true,
                imageRender: () => <ShoppingItem queries={queries.map(query => query['bottom'] ?? '')} shopping={bottom} />,
                toolbarRender: () => null
              }} />
            </div>
            <p className="mt-2 text-sm line-clamp-2">{queries[0]['bottom']}</p>
          </div>
        )}
      </div>
      <div className="grid grid-rows-4 gap-y-4">
        {glasses && (
          <div>
            <div className="flex items-center overflow-hidden w-20 h-20">
              <Image src={glasses.thumbnail} preview={{
                destroyOnClose: true,
                imageRender: () => <ShoppingItem queries={queries.map(query => query['glasses'] ?? '')} shopping={glasses} />,
                toolbarRender: () => null
              }} />
            </div>
            <p className="mt-2 text-sm line-clamp-2">{queries[0]['glasses']}</p>
          </div>
        )}
        {accessories && (
          <div>
            <div className="flex items-center overflow-hidden w-20 h-20">
              <Image src={accessories.thumbnail} preview={{
                destroyOnClose: true,
                imageRender: () => <ShoppingItem queries={queries.map(query => query['accessories'] ?? '')} shopping={accessories} />,
                toolbarRender: () => null
              }} />
            </div>
            <p className="mt-2 text-sm line-clamp-2">{queries[0]['accessories']}</p>
          </div>
        )}
        {bag && (
          <div>
            <div className="flex items-center overflow-hidden w-20 h-20">
              <Image src={bag.thumbnail} preview={{
                destroyOnClose: true,
                imageRender: () => <ShoppingItem queries={queries.map(query => query['bag'] ?? '')} shopping={bag} />,
                toolbarRender: () => null
              }} />
            </div>
            <p className="mt-2 text-sm line-clamp-2">{queries[0]['bag']}</p>
          </div>
        )}
        {shoes && (
          <div>
            <div className="flex items-end overflow-hidden w-20 h-20">
              <Image src={shoes.thumbnail} preview={{
                destroyOnClose: true,
                imageRender: () => <ShoppingItem queries={queries.map(query => query['shoes'] ?? '')} shopping={shoes} />,
                toolbarRender: () => null
              }} />
            </div>
            <p className="mt-2 text-sm line-clamp-2">{queries[0]['shoes']}</p>
          </div>
        )}
      </div>
    </div>
  )
}

