import request from '@/utils/request'

export interface RecommendQuestion {
  id?: string
  question: string
  llmQuery?: string
  questionForQuery?: string
  imageUrl: string
  mobileImageUrl?: string
  avatar: string
  username?: string
  updatedAt?: string
}

interface RecommendQuestionResponse {
  id: string
  question: string
  llm_query: string
  question_for_query: string
  image_url: string
  mobile_image_url: string
  username: string
  avatar: string
  updated_at: string
}

export async function queryQuestions(page: number, pageSize: number) {
  const res = await request.get<RecommendQuestionResponse[]>(`/recommend_questions?page=${page}&limit=${pageSize}`)

  return res?.map<RecommendQuestion>(v => ({
    id: v.id,
    question: v.question,
    llmQuery: v.llm_query,
    questionForQuery: v.question_for_query,
    imageUrl: v.image_url,
    mobileUrl: v.mobile_image_url,
    username: v.username,
    avatar: v.avatar,
    updatedAt: v.updated_at
  }))
}

export function createQuestion(question: RecommendQuestion) {
  return request.post('/recommend_questions', {
    question: question.question,
    question_for_query: question.questionForQuery,
    llm_query: question.llmQuery,
    image_url: question.imageUrl,
    mobile_image_url: question.mobileImageUrl,
    username: question.username,
    avatar: question.avatar
  })
}

export function updateQuestion(id: string, question: RecommendQuestion) {
  return request.post(`/recommend_questions/${id}`, {
    question: question.question,
    question_for_query: question.questionForQuery,
    llm_query: question.llmQuery,
    image_url: question.imageUrl,
    mobile_image_url: question.mobileImageUrl,
    username: question.username,
    avatar: question.avatar
  })
}

export function archiveQuestion(id: string) {
  return request.post(`/recommend_questions/${id}/archive`)
}