import { Button, Flex } from 'antd'
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi'

export default function Pagination({ disableNext, disablePrev, onNext, onPrev }: { disableNext?: boolean; disablePrev?: boolean; onNext?: () => void; onPrev?: () => void }) {
  return (
    <Flex justify="space-between">
      <Button icon={<HiOutlineChevronLeft />} disabled={disablePrev} onClick={onPrev}>Prev</Button>
      <Button icon={<HiOutlineChevronRight />} iconPosition="end" disabled={disableNext} onClick={onNext}>Next</Button>
    </Flex>
  )
}