import useNavigation from '@/hooks/useNavigation'
import type { SheetRow } from '@/services/sheets'
import { HiExternalLink } from 'react-icons/hi'
import { Table, type TableProps, Tabs, type TabsProps } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

const columns: TableProps<SheetRow>['columns'] = [
  {
    key: 'title',
    title: 'Title',
    dataIndex: 'title',
    render: (text, record) => (
      <Link to={record.link} target="_blank">
        <HiExternalLink className="inline-block align-middle" />
        <span className="inline-block align-middle ml-1">{text}</span>
      </Link>
    )
  },
  {
    key: 'owner',
    title: 'Owner',
    dataIndex: 'owner',
    render: text => `Owner: ${text ?? ''}`
  }
]

export default function Home() {
  const { rows, active, loading } = useNavigation()
  const [activeTab, setActiveTab] = useState('')

  const path = useMemo(() => {
    if (!rows.length) {
      return []
    }

    const arr = active.split('-')
    if (arr.length === 2) {
      return arr
    }

    return [rows[0].department, rows[0].section]
  }, [active, rows])

  const tabItems = useMemo<TabsProps['items']>(() => {
    if (!path.length) {
      return []
    }

    const tabs = rows.filter(v => v.department === path[0] && v.section === path[1]).map(v => v.tab)

    return [...new Set(tabs)].map(v => ({ key: v, label: v }))
  }, [path, rows])

  const dataSource = useMemo(() => {
    if (!(activeTab || tabItems?.length) || !path.length) {
      return []
    }

    return rows.filter(v => v.department === path[0] && v.section === path[1] && v.tab === (activeTab || tabItems?.[0].key))
  }, [tabItems, activeTab, rows, path])

  const handleTabChange = (val: string) => {
    setActiveTab(val)
  }

  useEffect(() => {
    if (active && tabItems) {
      setActiveTab(tabItems[0].key)
    }
  }, [active, tabItems])

  return (
    <section>
      {!loading && <Tabs size="large" items={tabItems} onChange={handleTabChange} />}
      <Table rowKey="id" size="large" dataSource={dataSource} columns={columns} showHeader={false} pagination={false} loading={loading} />
    </section>
  )
}
