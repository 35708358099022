import request from '@/utils/request'

export interface Shopping {
  title: string
  source: string
  link: string
  price: string
  thumbnail: string
}

export interface StealStyleQuery {
  top?: string
  bottom?: string
  shoes?: string
  bag?: string
  accessories?: string
  glasses?: string
}

interface StealStyleResponse {
  queries: StealStyleQuery[]
  results: {
    [key: string]: {
      shopping_results: Shopping[]
    }
  }
  text: string
}

export async function stealStyle(image: File) {
  const formData = new FormData()
  formData.append('file', image)

  const res = await request.postForm<StealStyleResponse>('/steal_style', formData)
  if (!res) {
    return null
  }

  return {
    text: res.text,
    queries: res.queries,
    shopping: Object.entries(res.results).reduce((acc, [key, value]) => {
      acc[key] = value.shopping_results
      return acc
    }, {} as { [key: string]: Shopping[] })
  }
}

export async function changeQuery(query: string) {
  const res = await request.get<Pick<StealStyleResponse, 'results'>>(`/steal_style/query?q=${query}`)

  return {
    currentQuery: '',
    shopping: res?.results
  }
}
