import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { App, type TableProps } from 'antd'

export default function useTable<T>(query: (page: number, pageSize: number, searchParams: URLSearchParams) => Promise<{ list: T[]; total: number }>, pageSize = 30) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { message } = App.useApp()
  const [list, setList] = useState<T[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const page = useMemo(() => parseInt(searchParams.get('page') || '1'), [searchParams])

  const changePage = useCallback((val: number) => {
    setSearchParams(prev => {
      prev.set('page', val.toString())

      return prev
    })
  }, [setSearchParams])

  const fetchList = useCallback(async () => {
    setLoading(true)
    try {
      const page = parseInt(searchParams.get('page') || '1')
      const limit = parseInt(searchParams.get('page_size') || pageSize.toString())

      const res = await query(page, limit, searchParams)

      setList(res.list ?? [])
      setTotal(res.total) 
    } catch(e) {
      if (e instanceof Error) {
        message.error(e.message)
      }
    } finally {
      setLoading(false)
    }
  }, [searchParams, message, query, pageSize])

  useEffect(() => {
    fetchList()
  }, [fetchList])

  const tableProps = useMemo<TableProps<T>>(() => ({
    rowKey: 'id',
    dataSource: list,
    loading,
    pagination: {
      position: ['bottomCenter'],
      current: page,
      total,
      pageSize: parseInt(searchParams.get('page_size') || pageSize.toString()),
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      onChange: changePage,
      onShowSizeChange: (_, size) => {
        setSearchParams(prev => {
          prev.set('page_size', size.toString())

          return prev
        })
      }
    }
  }), [list, loading, page, changePage, total, pageSize, searchParams, setSearchParams])

  return { list, total, page, loading, searchParams, changePage, changeSearch: setSearchParams, fetchList, tableProps }
}