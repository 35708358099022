import MarkdownRender from '@/components/MarkdownRender'
import { createTrendBriefStream, createTrendPages } from '@/services/trendPage'
import { App, Button, Checkbox, Col, Divider, Input, Row, Skeleton, type CheckboxProps } from 'antd'
import { useMemo, useRef, useState } from 'react'

export default function BriefForm({ onSuccess }: { onSuccess?: () => void }) {
  const { message } = App.useApp()
  const [trend, setTrend] = useState('')
  const [sourceType, setSourceType] = useState('')
  const [briefs, setBriefs] = useState<{ brief: string; checked: boolean }[]>([])
  const [loading, setLoading] = useState(false)
  const content = useRef('')

  const allChecked = useMemo(() => briefs.every(brief => brief.checked), [briefs])

  const handleTrendSubmit = async () => {
    if (!trend) {
      return
    }

    setLoading(true)
    try {
      const stream = await createTrendBriefStream(trend, sourceType)

      const decoder = new TextDecoder('utf-8')
      let tmpStr = ''

      while (true) {
        const { done, value } = await stream.read()
        if (done) {
          break
        }

        const chunk = decoder.decode(value, { stream: true })
        if (!tmpStr) {
          tmpStr = chunk
          continue
        }

        if (!chunk.startsWith('id: ')) {
          tmpStr += chunk
          continue
        }
        
        const arr = tmpStr.split('\n')
        if (!arr[2]?.startsWith('data: ')) {
          continue
        }

        const data = arr[2].slice(5).trim()
        const brief = JSON.parse(data)
        if (brief['node_title'] === 'End') {
          content.current += brief['content']

          setBriefs(content.current.split('|||').filter(v => v).map(v => ({ brief: v.trim(), checked: false })))
        }

        tmpStr = chunk
      }
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleCheck = (index: number, checked: boolean) => {
    setBriefs(briefs.map((brief, i) => ({ ...brief, checked: i === index ? checked : brief.checked })))
  }

  const handleCheckAll: CheckboxProps['onChange'] = e => {
    setBriefs(briefs.map(brief => ({ ...brief, checked: e.target.checked })))
  }

  const handleClear = () => {
    setBriefs([])
    setSourceType('')
    setTrend('')
  }

  const handleBriefSubmit = async () => {
    if (!briefs.length || !sourceType) {
      return
    }

    setLoading(true)
    try {
      await createTrendPages(trend, sourceType, briefs.filter(brief => brief.checked).map(brief => brief.brief)) 
      onSuccess?.()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  if (briefs.length > 0) {
    return (
      <section className="space-y-2">
        {briefs.map((brief, index) => (
          <div key={index}>
            <Checkbox checked={brief.checked} onChange={e => handleCheck(index, e.target.checked)}>
              <div className="ml-4">
                <MarkdownRender text={brief.brief} />
              </div>
            </Checkbox>
            <Divider />
          </div>
        ))}
        <Row gutter={16} align="middle">
          <Col>
            <Checkbox checked={allChecked} onChange={handleCheckAll}>All</Checkbox>
          </Col>
          <Col>
            <Button type="primary" loading={loading} disabled={briefs.every(brief => !brief.checked) || !sourceType} onClick={handleBriefSubmit}>Submit</Button>
          </Col>
          <Col>
            <Button onClick={handleClear}>Clear</Button>
          </Col>
        </Row>
      </section>
    )
  }

  return (
    <section className="space-y-4">
      <Row gutter={16}>
        <Col flex="auto">
          <Input placeholder="Source Type" value={sourceType} onChange={e => setSourceType(e.target.value)} />
        </Col>
        <Col flex="auto">
          <Input placeholder="Trend" value={trend} onPressEnter={handleTrendSubmit} onChange={e => setTrend(e.target.value)} />
        </Col>
        <Col>
          <Button type="primary" loading={loading} disabled={!trend} onClick={handleTrendSubmit}>Submit</Button>
        </Col>
      </Row>
      {loading && <Skeleton active />}
    </section>
  )
}