import request from '@/utils/request'
import { Page } from './page'
import { PageResponse } from './page'

export enum YoutubePageStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
  Processing = 'processing'
}

export interface YoutubePage {
  id: string
  searchQuery: string
  title: string
  link: string
  subtitle?: string
  status?: YoutubePageStatus
  markdown?: string
  pageId?: string
  updatedAt: string
}

export interface YoutubePageResponse {
  id: string
  search_query: string
  title: string
  link: string
  subtitle?: string
  status?: YoutubePageStatus
  markdown?: string
  page_id?: string
  updated_at: string
}

export async function queryYoutubePages(page: number, pageSize: number, status?: string) {
  const res = await request.get<{ data: YoutubePageResponse[]; total: number; pages: PageResponse[] }>('/youtube_pages', {
    page,
    page_size: pageSize,
    status
  })

  const list = (res?.data ?? []).map<YoutubePage>(item => ({
    id: item.id,
    searchQuery: item.search_query,
    title: item.title,
    link: item.link,
    status: item.status,
    updatedAt: item.updated_at,
    pageId: item.page_id
  }))

  const pages = (res?.pages ?? []).map<Page>(item => ({
    id: item._id,
    status: item.status,
    coverImage: item.cover_image,
    outline: {
      postTitle: item.outline?.post_title ?? '',
      postSubtitle: item.outline?.post_subtitle ?? '',
      intro: item.outline?.intro ?? '',
      body: item.outline?.body?.map(v => ({ chapterTitle: v.chapter_title })) ?? []
    },
    createdAt: item.created_at,
    updatedAt: item.updated_at
  }))

  return { list, total: res?.total ?? 0, pages }
}

export async function queryYoutubePage(id: string): Promise<YoutubePage | null> {
  const res = await request.get<YoutubePageResponse>(`/youtube_pages/${id}`)

  return res && {
    id: res.id,
    searchQuery: res.search_query,
    title: res.title,
    link: res.link,
    status: res.status,
    updatedAt: res.updated_at,
    pageId: res.page_id,
    markdown: res.markdown
  }
}

export function processYoutubePage(id: string) {
  return request.post(`/youtube_pages/${id}`)
}

export function retryYoutubePage(id: string) {
  return request.post(`/youtube_pages/${id}/retry`)
}

export function batchYoutubePages(ids: string[]) {
  return request.post('/batch/youtube_pages', ids)
}

export function batchRetryYoutubePages(ids: string[]) {
  return request.post('/batch/retry_youtube_pages', ids)
}
