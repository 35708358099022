import TableList, { type TableListProps } from '@/components/TableList'
import useTable from '@/hooks/useTable'
import { type Prompt, queryPrompts, createPrompt, updatePrompt } from '@/services/prompt'
import { type FormInstance, Input, type TableProps } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import ItemForm from './components/ItemForm'
import ListActions from './components/ListActions'
import AddToAgent from './components/AddToAgent'

const Search = Input.Search

export default function Prompts() {
  const [open, setOpen] = useState(false)
  const [addToAgentId, setAddToAgentId] = useState<string>('')
  const { tableProps, fetchList, changeSearch } = useTable(
    useCallback((page, pageSize, searchParams) => queryPrompts(page, pageSize, searchParams.get('name') ?? ''), [])
  )

  const handleSearch = (val: string) => {
    changeSearch({ name: val })
  }

  const handleSubmit = async (form: FormInstance<Pick<Prompt, 'name' | 'prompt' | 'description'>>, record?: Prompt, isEdit?: boolean) => {
    const values = await form.validateFields()
    if (isEdit) {
      if (!record) {
        return
      }

      await updatePrompt(record.id, values)
    } else {
      await createPrompt(values)
    }

    fetchList()
  }

  const handleAddToAgent = (id: string) => {
    setOpen(true)
    setAddToAgentId(id)
  }

  const handleAddToAgentSuccess = () => {
    fetchList()
    setOpen(false)
  }

  const columns: TableProps<Prompt>['columns'] = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description'
    },
    {
      title: 'Operator',
      key: 'operator',
      dataIndex: 'operator'
    },
    {
      title: 'Updated At',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: value => dayjs.utc(value).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')
    }
  ]

  // Prompt table list
  const tableListProps: TableListProps<Prompt, Pick<Prompt, 'name' | 'prompt' | 'description'>> = {
    tableProps: { ...tableProps, columns },
    formTitle: 'Prompt',
    headerLeft: (
      <Search className="w-96" placeholder="Search name" size="large" allowClear onSearch={handleSearch} />
    ),
    listActions: record => <ListActions id={record.id} onAddToAgent={handleAddToAgent} />,
    onSubmit: handleSubmit
  }

  return (
    <>
      <TableList {...tableListProps}>
        {(form, isEdit) => <ItemForm form={form} isEdit={isEdit} />}
      </TableList>
      <AddToAgent id={addToAgentId} open={open} onSuccess={handleAddToAgentSuccess} onCancel={() => setOpen(false)} />
    </>
  )
}