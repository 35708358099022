import MarkdownRender from '@/components/MarkdownRender'
import type { SragMessage } from '@/services/srag'
import { Skeleton } from 'antd'
import { useEffect, useRef } from 'react'
import MiddleSteps from './MiddleSteps'

export default function Messages({ messages, content, loading }: { messages: SragMessage[]; content: string; loading: boolean }) {
  const container = useRef<HTMLDivElement>(null)

  const handleMutation: MutationCallback = (mutations: MutationRecord[]) => {
    if (mutations[0] && mutations[0].addedNodes[0]) {
      const element = mutations[0].addedNodes[0] as Element

      element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const observer = new MutationObserver(handleMutation)
    if (container.current) {
      observer.observe(container.current, {
        childList: true
      })

      return () => {
        observer.disconnect()
      }
    }
  }, [])

  return (
    <div ref={container}>
      {messages.map((v, i) => {
        const text = (v.done ? v.output?.result.generic_llm[0] : content) ?? ''

        return (
          <div key={i} className="pb-4 mb-4 border-b">
            <h1 className="pb-4 mb-4 text-2xl">{v.query}</h1>
            <MiddleSteps steps={v.output?.result?.log_dict} />
            {!text && loading ? <Skeleton active /> : <MarkdownRender text={text} />}
          </div>
        )
      })}
    </div>
  )
}