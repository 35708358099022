import { Row, Col, Select, Input } from 'antd'
import { useState, type ChangeEvent } from 'react'
import { HiSearch } from 'react-icons/hi'

interface FiltersProps<T> {
  searchParams: URLSearchParams
  options: [string, T][]
  showFilenameSearch?: boolean
  onStatusChange: (value: T) => void
  onFilenameChange?: (value: string) => void
}

export default function Filters<T>({ searchParams, options, showFilenameSearch, onStatusChange, onFilenameChange }: FiltersProps<T>) {
  const [filename, setFilename] = useState('')

  const handleFilenameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilename(e.target.value)
  }

  const handleFilenameSearch = () => {
    onFilenameChange?.(filename)
  }

  const handleFilenameClear = () => {
    setFilename('')
    onFilenameChange?.('')
  }

  const selectOptions = options.map(([key, value]) => ({
    label: key,
    value: value
  }))

  return (
    <Row className="w-96" gutter={8}>
      <Col span={10}>
        <Select
          size="large"
          value={(searchParams.get('status') || undefined) as T}
          style={{ width: '100%' }}
          allowClear
          options={selectOptions}
          placeholder="Filter Status"
          onChange={onStatusChange}
        />
      </Col>
      {showFilenameSearch && (
        <Col span={14}>
          <Input
            size="large"
            placeholder="Search Filename"
            value={filename}
            prefix={<HiSearch />}
            allowClear
            onChange={handleFilenameChange}
            onPressEnter={handleFilenameSearch}
            onClear={handleFilenameClear}
          />
        </Col>
      )}
    </Row>
  )
}