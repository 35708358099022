import TableList, { TableListProps } from '@/components/TableList'
import useTable from '@/hooks/useTable'
import { type Agent, type AgentData, type AgentPrompt, createAgent, queryAgent, queryAgents, updateAgent } from '@/services/agent'
import { App, FormInstance, type TableProps } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import ItemForm from './components/ItemForm'

export default function Agents() {
  const { message } = App.useApp()
  const [prompts, setPrompts] = useState<AgentPrompt[]>([])
  const { tableProps, fetchList } = useTable(
    useCallback((page, pageSize) => queryAgents(page, pageSize), [])
  )

  const handleEdit = async (id: string) => {
    try {
      const agent = await queryAgent(id)
      if (agent) {
        setPrompts(Object.keys(agent.prompts).map(p => ({ key: p, promptId: agent.prompts[p].promptId, promptName: agent.prompts[p].promptName })))
      }
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message)
      }
    }
  }

  const handleSubmit = async (form: FormInstance<AgentData>, record?: Omit<Agent, 'prompts'>, isEdit?: boolean) => {
    const data = await form.validateFields()

    const promptsMap = prompts.reduce<AgentData['prompts']>((p, c) => ({
      ...p,
      [c.key]: {
        promptId: c.promptId,
        promptName: c.promptName,
      }
    }), {})

    if (isEdit) {
      if (!record) return

      await updateAgent(record.id, { ...data, prompts: promptsMap })
    } else {
      await createAgent({ ...data, prompts: promptsMap })
    }

    fetchList()
  }

  const columns: TableProps<Omit<Agent, 'prompts'>>['columns'] = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Updated At',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: value => dayjs.utc(value).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss'),
    },
  ]

  const tableListProps: TableListProps<Omit<Agent, 'prompts'>, AgentData> = {
    tableProps: { ...tableProps, columns },
    formTitle: 'Agent',
    onAdd: () => {
      setPrompts([])
    },
    onEdit: (_, record) => handleEdit(record.id),
    onSubmit: handleSubmit,
  }

  return (
    <TableList {...tableListProps}>
      {(form, isEdit) => <ItemForm form={form} prompts={prompts} isEdit={isEdit} onPromptsChange={setPrompts} />}
    </TableList>
  )
}