import { Button, Checkbox, CheckboxProps, Col, Input, Row } from 'antd'
import Messages from './Messages'
import type { SragMessage, SragParams } from '@/services/srag'
import DebugForm from './DebugForm'
import { ChangeEvent } from 'react'

interface PanelProps {
  query: string
  params: SragParams
  gateway: string
  messages: SragMessage[]
  content: string
  withContext: boolean
  loading: boolean
  hideParams?: boolean
  onSubmit: () => void
  onParamsChange: (params: SragParams) => void
  onGatewayChange: (gateway: string) => void
  onQueryChange: (query: string) => void
  onWithContextChange: (withContext: boolean) => void
}

export default function Panel({ messages, content, query, params, gateway, withContext, hideParams, loading, onSubmit, onParamsChange, onGatewayChange, onQueryChange, onWithContextChange }: PanelProps) {
  const handleQuery = (e: ChangeEvent<HTMLInputElement>) => {
    onQueryChange(e.target.value)
  }

  const handleWithContext: CheckboxProps['onChange'] = e => {
    onWithContextChange(e.target.checked)
  }

  return (
    <section className="relative">
      <Row gutter={20}>
        {!hideParams && (
          <Col span={6}>
            <div className="overflow-auto" style={{ height: 'calc(100vh - 64px)' }}>
              <DebugForm value={params} gateway={gateway} onChange={onParamsChange} onGatewayChange={onGatewayChange} />
              <Input
                size="large"
                value={query}
                disabled={loading}
                allowClear
                placeholder="Enter your query here"
                onChange={handleQuery}
                onPressEnter={onSubmit}
              />
              <div className="text-center mt-4 space-x-3">
                <Button size="large" type="primary" loading={loading} onClick={onSubmit}>Submit</Button>
                <Checkbox checked={withContext} onChange={handleWithContext}>with context</Checkbox>
              </div>
            </div>
          </Col>
        )}
        <Col span={hideParams ? 24 : 18}>
          <div className="overflow-auto" style={{ height: 'calc(100vh - 64px)' }}>
            <Messages messages={messages} content={content} loading={loading} />
          </div>
        </Col>
      </Row>
    </section>
  )
}