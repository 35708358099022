import { Card } from 'antd'
import { type Shopping } from '@/services/stealStyle'

export default function ShoppingItem({ queries, shopping }: { queries: string[]; shopping: Shopping }) {
  return (
    <Card cover={<img src={shopping.thumbnail} className="max-w-[400px] block mx-auto" />}>
      <Card.Meta title={shopping.title} description={shopping.price} />
      <div className="text-left mt-4">
        {queries.map(query => (
          <div key={query}>
            <a href={`https://google.com/search?tbm=shop&q=${query}`} target="_blank" rel="noreferrer"># {query}</a>
          </div>
        ))}
      </div>
    </Card>
  )
}
