import { archiveQuestion } from '@/services/recommendQuestion'
import { App, Button, Space, Popconfirm } from 'antd'
import { useState } from 'react'
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'

export default function Actions({ id, onArchived, onEdit }: { id: string; onArchived: () => void; onEdit: (id: string) => void }) {
  const { message } = App.useApp()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleEdit = () => {
    onEdit(id)
  }

  const handleArchive = async () => {
    setLoading(true)
    try {
      await archiveQuestion(id)

      onArchived()
    } catch(e) {
      if (e instanceof Error) {
        message.error(e.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleShowConfirm = () => {
    setOpen(true)
  }

  return (
    <Space>
      <Button icon={<HiOutlinePencil />} onClick={handleEdit} />
      <Popconfirm
        title="Archive Recommend Question"
        description="Are you sure to archive this question?"
        open={open}
        onConfirm={handleArchive}
        onCancel={handleCancel}
        okButtonProps={{ loading }}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" danger icon={<HiOutlineTrash />} onClick={handleShowConfirm} />
      </Popconfirm>
    </Space>
  )
}