import type { PageData } from '@/services/page'

export default function markdownJSON(text: string) {
  const lines = text.split(/\n|\\n/)

  const chapterTitles = lines.filter(line => /^#{2,6}\s/.test(line))
  if (!chapterTitles.length) {
    return { outline: { postTitle: '', postSubtitle: '', intro: '', body: [] }, chapters: [], personaId: '' }
  }

  const headingLevel = chapterTitles.reduce((res, item) => Math.min(res, item.match(/^#+\s/)![0].trim().length ?? Infinity), Infinity)
  const chapterTitleLevel = `${'#'.repeat(headingLevel)} `

  return lines.reduce<PageData>((acc, cur) => {
    const { outline, chapters } = acc

    if (!cur) {
      return acc
    }

    if (cur.startsWith('::personaID[') && cur.endsWith(']')) {
      const match = cur.match(/::personaID\[(\d+)\]/)
      if (match) {
        return { ...acc, personaId: match[1] }
      }
    }

    if (cur.startsWith('# ')) {
      return { ...acc, outline: { ...outline, postTitle: cur.slice(2).trim() } }
    }

    if (cur.startsWith(chapterTitleLevel)) {
      const chapterTitle = cur.slice(headingLevel).trim()

      return {
        ...acc,
        outline: { ...outline, body: [...outline.body, { chapterTitle }] },
        chapters: [
          ...chapters,
          {
            content: { chapterTitle, content: '', productList: [] }
          }
        ]
      }
    }

    if (outline.postTitle && !outline.intro && !chapters.length) {
      return { ...acc, outline: { ...outline, intro: cur } }
    }

    const productListMatch = cur.match(/::item-cards\s*\[(.*?)\]/g)
    const productList = productListMatch ? productListMatch.map(match => {
      const content = match.match(/\[(.*?)\]/)?.[1]
      const items = content?.split(',')

      return { productQuery: items?.[0].trim() ?? '' }
    }).filter(item => item.productQuery !== '') : []

    const lastChapter = chapters[chapters.length - 1]
    if (!lastChapter) {
      return acc
    }

    return {
      ...acc,
      chapters: [
        ...chapters.slice(0, -1),
        {
          content: {
            ...lastChapter.content,
            content: [lastChapter.content.content, cur].filter(v => v).join('\n'),
            productList: [...lastChapter.content.productList, ...productList]
          }
        }
      ]
    }
  }, { outline: { postTitle: '', postSubtitle: '', intro: '', body: [] }, chapters: [], personaId: '' })
}