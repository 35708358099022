import { applyPrompt, deletePrompt } from '@/services/prompt'
import { App, Space, Popconfirm, Tooltip, Button } from 'antd'
import { AiOutlineRobot } from 'react-icons/ai'
import { HiOutlineCloudUpload, HiOutlineTrash } from 'react-icons/hi'

export default function ListActions({ id, onAddToAgent }: { id: string; onAddToAgent: (id: string) => void }) {
  const { message } = App.useApp()

  const handleDelete = async () => {
    try {
      await deletePrompt(id)
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    }
  }

  const handleAddToAgent = () => {
    onAddToAgent(id)
  }

  const handleApply = async () => {
    try {
      await applyPrompt(id)

      message.success('Prompt has been applied to all agents containing this prompt')
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    }
  }

  return (
    <Space>
      <Tooltip title="Add to Agent">
        <Button type="primary" icon={<AiOutlineRobot />} onClick={handleAddToAgent} />
      </Tooltip>
      <Popconfirm title="Are you sure you want to apply this version to all agents containing this prompt?" onConfirm={handleApply}>
        <Tooltip title="Apply">
          <Button type="primary" icon={<HiOutlineCloudUpload />} />
        </Tooltip>
      </Popconfirm>
      <Popconfirm title="Are you sure you want to delete this prompt?" onConfirm={handleDelete}>
        <Tooltip title="Delete">
          <Button type="primary" danger icon={<HiOutlineTrash />} />
        </Tooltip>
      </Popconfirm>
    </Space>
  )
}
