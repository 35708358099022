import { addFavicon, type Favicon, type FaviconData, queryFavicons, updateFavicon } from '@/services/favicon'
import { type FormInstance, Input, type TableProps } from 'antd'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import ItemForm from './components/ItemForm'
import useTable from '@/hooks/useTable'
import TableList, { TableListProps } from '@/components/TableList'

const { Search } = Input

export default function Favicons() {
  const { tableProps, changeSearch, fetchList } = useTable(
    useCallback(
      (page, pageSize, searchParams) => queryFavicons(page, pageSize, searchParams.get('domain') || ''),
      []
    )
  )

  const handleSearch = (val: string) => {
    changeSearch({ domain: val })
  }

  const handleSubmit = async (form: FormInstance<Pick<FaviconData, 'domain' | 'originUrl'>>, record?: Favicon, isEdit?: boolean) => {
    const values = await form.validateFields()
    const data = { ...values, faviconUrl: values.originUrl }

    if (isEdit) {
      if (!record) {
        return
      }

      await updateFavicon(record.id, data)
    } else {
      await addFavicon(data)
    }

    fetchList()
  }

  const columns: TableProps<Favicon>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain'
    },
    {
      title: 'Favicon',
      dataIndex: 'faviconUrl',
      key: 'faviconUrl',
      render: src => (
        <>
          <img src={src} className="w-8" />
          <p className="mt-2">{src}</p>
        </>
      )
    },
    {
      title: 'Origin URL',
      dataIndex: 'originUrl',
      key: 'originUrl',
      render: src => (
        <>
          <img src={src} className="w-8" />
          <p className="mt-2">{src}</p>
        </>
      )
    },
    {
      title: 'Update Time',
      dataIndex: 'updateAt',
      key: 'updatedAt',
      render: text => dayjs(text).format('YYYY-MM-DD hh:mm:ss')
    }
  ]

  const tableListProps: TableListProps<Favicon, Pick<FaviconData, 'domain' | 'originUrl'>> = {
    tableProps: { ...tableProps, columns },
    headerLeft: <Search size="large" allowClear onSearch={handleSearch} />,
    onSubmit: handleSubmit
  }

  return (
    <TableList {...tableListProps }>
      {(form, isEdit) => <ItemForm form={form} isEdit={isEdit} />}
    </TableList>
  )
}