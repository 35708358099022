import { Layout as AntdLayout, Menu, type MenuProps } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, Outlet, Link } from 'react-router-dom'
import { querySheets, SheetRow } from '@/services/sheets'
import logoUrl from '@/assets/logo.svg'
import { NavigationContext } from '@/hooks/useNavigation'
import { HiOutlineHome, HiOutlineLightBulb, HiOutlineChatAlt2, HiOutlineUserGroup, HiOutlineDocumentText } from 'react-icons/hi'
import { TbFavicon, TbPhotoSearch } from 'react-icons/tb'
import { GiAmpleDress } from 'react-icons/gi'
import { AiOutlineRobot } from 'react-icons/ai'

type MenuItem = Required<MenuProps>['items'][number]

const { Sider, Content } = AntdLayout

const whitelistItems: MenuItem[] = [
  {
    key: '/',
    label: 'Home',
    icon: <HiOutlineHome />,
  },
  {
    key: '/steal_style',
    label: 'Steal Her Style',
    icon: <GiAmpleDress />
  }
]

const items: MenuItem[] = [
  {
    key: '/',
    label: 'Navigations',
    icon: <HiOutlineHome />,
  },
  {
    key: '/recommend_questions',
    label: 'Recommend Questions',
    icon: <HiOutlineLightBulb />
  },
  {
    key: '/srag_ask',
    label: 'SRAG',
    icon: <HiOutlineChatAlt2 />
  },
  {
    key: '/favicons',
    label: 'Favicons',
    icon: <TbFavicon />
  },
  {
    key: '/personas',
    label: 'Personas',
    icon: <HiOutlineUserGroup />
  },
  {
    key: 'pages',
    label: 'Pages',
    icon: <HiOutlineDocumentText />,
    children: [
      { key: '/pages', label: 'Markdown to Page' },
      { key: '/trend_to_pages', label: 'Trend to Page' },
      { key: '/youtube_to_page', label: 'Youtube to Page' }
    ]
  },
  {
    key: '/image_search',
    label: 'Image Search',
    icon: <TbPhotoSearch />
  },
  {
    key: '/steal_style',
    label: 'Steal Her Style',
    icon: <GiAmpleDress />
  },
  {
    key: 'prompts',
    label: 'Prompts',
    icon: <AiOutlineRobot />,
    children: [
      { key: '/prompts', label: 'List' },
      { key: '/agents', label: 'Agents' }
    ]
  }
]

export default function Layout({ inWhitelist, isSrp }: { inWhitelist: boolean; isSrp: boolean }) {
  const navigate = useNavigate()
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(false)
  const [rows, setRows] = useState<SheetRow[]>([])
  const [activeRow, setActiveRow] = useState('')
  const [loading, setLoading] = useState(false)

  const menuItems = useMemo<MenuItem[]>(() => {
    if (inWhitelist) {
      return whitelistItems
    }

    if (!rows.length) {
      return items
    }

    const tree = rows.reduce<{ [key: string]: string[] }>((res, item) => {
      const dep = res[item.department]
      if (!dep) {
        return { ...res, [item.department]: [item.section] }
      }

      return { ...res, [item.department]: [...new Set([...dep, item.section])] }
    }, {})

    return [
      {
        ...items[0],
        children: Object.entries(tree).map(v => ({
          key: v[0],
          label: v[0],
          children: v[1].map(j => ({
            key: `${v[0]}-${j}`,
            label: j
          }))
        }))
      },
      ...items.slice(1)
    ] as MenuItem[]
  }, [rows, inWhitelist])

  const fetchSheets = useCallback(async () => {
    setLoading(true)
    try {
      const res = await querySheets()
      const rows = res?.slice(1).map<SheetRow>((v, i) => ({
        id: i + 1,
        department: v[0],
        section: v[1],
        tab: v[2],
        title: v[3],
        link: v[4],
        owner: v[5]
      }))

      setRows(rows ?? [])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleMenu: MenuProps['onClick'] = ({ key }) => {
    if (key.startsWith('/')) {
      navigate(key)
      return
    }

    setActiveRow(key)
    navigate('/')
  }

  useEffect(() => {
    if (isSrp) {
      fetchSheets()
    }
  }, [fetchSheets, isSrp])

  return (
    <AntdLayout className="h-screen">
      <Sider width={240} theme="light" collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
        {import.meta.env.VITE_ENV === 'production' && (
          <div className="py-4">
            <Link to="/">
              <img className="mx-auto" src={logoUrl} />
            </Link>
          </div>
        )}
        <Menu mode="inline" selectedKeys={[location.pathname]} items={menuItems} onClick={handleMenu} />
      </Sider>
      <AntdLayout>
        <NavigationContext.Provider value={{ rows, active: activeRow, loading }}>
          <Content className="p-4 h-full">
            <div className="bg-white h-full rounded-lg p-4">
              <Outlet />
            </div>
          </Content>
        </NavigationContext.Provider>
      </AntdLayout>
    </AntdLayout>
  )
}
