import { addPromptToAgent, queryAgents } from '@/services/agent'
import debounce from '@/utils/debounce'
import { Modal, Form, Input, Select, Spin, App } from 'antd'
import { useState } from 'react'

const FormItem = Form.Item

export default function AddToAgent({ id, open, onSuccess, onCancel }: { id: string; open: boolean; onSuccess: () => void; onCancel: () => void }) {
  const { message } = App.useApp()
  const [agent, setAgent] = useState<{ label: string; value: string } | undefined>(undefined)
  const [options, setOptions] = useState<{ label: string; value: string }[]>([])
  const [promptKey, setPromptKey] = useState('')
  const [loading, setLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)

  const handleCancel = () => {
    setAgent(undefined)
    setPromptKey('')
    onCancel()
  }

  const handleSearch = debounce(async (value: string) => {
    if (value.trim() === '') {
      setOptions([])
      return
    }

    setSearchLoading(true)
    try {
      const res = await queryAgents(1, 10, value)

      setOptions(res.list.map(v => ({ label: v.name, value: v.id })))
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
      setOptions([])
    } finally {
      setSearchLoading(false)
    }
  }, 300)

  const handleSelect = (value: { label: string; value: string }) => {
    setAgent(value)
  }

  const handleOk = async () => {
    if (!agent || !promptKey) {
      message.error('Please select an agent and enter a prompt key')
      return
    }

    setLoading(true)
    try {
      await addPromptToAgent(agent.value, promptKey, id)

      onSuccess()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal title="Add to Agent" open={open} okButtonProps={{ loading }} onOk={handleOk} onCancel={handleCancel}>
      <Form>
        <FormItem label="Agent Name">
          <Select
            value={agent}
            placeholder="Select Agent Name"
            labelInValue
            showSearch
            options={options}
            filterOption={false}
            notFoundContent={searchLoading ? <Spin size="small" /> : null}
            onSearch={handleSearch}
            onSelect={handleSelect}
          />
        </FormItem>
        <FormItem label="Prompt Key">
          <Input value={promptKey} placeholder="Input Prompt Key" onChange={e => setPromptKey(e.target.value)} />
        </FormItem>
      </Form>
    </Modal>
  )
}