import TableList, { type TableListProps } from '@/components/TableList'
import useTable from '@/hooks/useTable'
import {
  batchYoutubePages,
  batchRetryYoutubePages,
  queryYoutubePage,
  queryYoutubePages,
  type YoutubePage,
  YoutubePageStatus
} from '@/services/youtubePage'
import { Drawer, Tag, type TableProps } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { AiOutlineSync } from 'react-icons/ai'
import { HiCheckCircle, HiOutlineClock, HiXCircle } from 'react-icons/hi2'
import dayjs from 'dayjs'
import YoutubeListButton from './components/YoutubeListButton'
import MarkdownPreview from './components/MarkdownPreview'
import PageExpand from './components/PageExpand'
import BatchButtons from './components/BatchButtons'
import Filters from './components/Filters'
import { Page } from '@/services/page'

const YoutubePageStatusMap = {
  [YoutubePageStatus.Pending]: {
    color: 'default',
    icon: <HiOutlineClock />
  },
  [YoutubePageStatus.Success]: {
    color: 'success',
    icon: <HiCheckCircle />
  },
  [YoutubePageStatus.Failed]: {
    color: 'error',
    icon: <HiXCircle />
  },
  [YoutubePageStatus.Processing]: {
    color: 'processing',
    icon: (
      <div className="animate-spin">
        <AiOutlineSync />
      </div>
    )
  }
}

export default function YoutubeToPage() {
  const [pages, setPages] = useState<{ [key: string]: Page }>({})
  const [expanded, setExpanded] = useState<string[]>([])
  const [open, setOpen] = useState(false)
  const [previewId, setPreviewId] = useState<string>('')
  const [selectedPages, setSelectedPages] = useState<YoutubePage[]>([])
  const { tableProps, searchParams, changeSearch, fetchList } = useTable(
    useCallback(async (page, pageSize, search) => {
      const { list, total, pages } = await queryYoutubePages(page, pageSize, search.get('status') ?? undefined)

      setPages(Object.fromEntries(pages.map(page => [page.id, page])))
      setExpanded(list.filter(record => record.status === YoutubePageStatus.Success).map(record => record.id))

      return { list, total }
    }, [])
  )

  const batchIds = useMemo(() => ({
    process: selectedPages.filter(record => record?.status === YoutubePageStatus.Pending).map(record => record.id),
    retry: selectedPages.filter(record => record?.status === YoutubePageStatus.Failed).map(record => record.id),
    success: selectedPages.filter(record => record?.status === YoutubePageStatus.Success).map(record => record.pageId).filter(Boolean)
  }), [selectedPages])

  const handleStatusChange = (value: YoutubePageStatus) => {
    changeSearch({ status: value ?? '' })
  }

  const handlePreview = async (id: string) => {
    setPreviewId(id)
    setOpen(true)
  }

  const handleBatchSuccess = () => {
    fetchList()
    setSelectedPages([])
  }

  const columns: TableProps<YoutubePage>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Query',
      dataIndex: 'searchQuery',
    },
    {
      title: 'Youtube Title',
      dataIndex: 'title',
      render: (_, record) => <a href={record.link} target="_blank" rel="noreferrer">{record.title}</a>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value: YoutubePageStatus) => {
        return (
          <Tag className="inline-flex items-center gap-x-1" icon={YoutubePageStatusMap[value].icon} color={YoutubePageStatusMap[value].color}>
            {value}
          </Tag>
        )
      }
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: value => (
        <p className="text-nowrap">{dayjs.utc(value).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')}</p>
      )
    }
  ]

  const tableListProps: TableListProps<YoutubePage> = {
    tableProps: {
      ...tableProps,
      columns,
      rowSelection: {
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedPages.map(record => record.id),
        getCheckboxProps: record => ({
          disabled: record.status === YoutubePageStatus.Processing
        }),
        onChange: (_, selectedRows) => {
          setSelectedPages(selectedRows)
        }
      },
      expandable: {
        expandedRowKeys: expanded,
        rowExpandable: record => record.status === YoutubePageStatus.Success,
        expandedRowRender: record => record.pageId && pages[record.pageId] && <PageExpand page={pages[record.pageId]} />,
        onExpand: (expanded, record) => {
          setExpanded(prev => {
            if (expanded) {
              return [...prev, record.id]
            } else {
              return prev.filter(id => id !== record.id)
            }
          })
        }
      }
    },
    hideAdd: true,
    hideEdit: true,
    headerLeft: (
      <Filters
        searchParams={searchParams}
        options={Object.entries(YoutubePageStatus)}
        onStatusChange={handleStatusChange}
      />
    ),
    headerActions: (
      <BatchButtons
        batchRetryIds={batchIds.retry}
        batchProcessIds={batchIds.process}
        batchSuccessIds={batchIds.success}
        batchRetry={batchRetryYoutubePages}
        batchProcess={batchYoutubePages}
        onSuccess={handleBatchSuccess}
      />
    ),
    listActions: record => (
      <YoutubeListButton
        page={record}
        onSuccess={fetchList}
        onPreview={handlePreview}
      />
    )
  }

  return (
    <>
      <TableList {...tableListProps} />
      <Drawer title="Markdown Preview" width={720} open={open} destroyOnClose onClose={() => setOpen(false)}>
        <MarkdownPreview id={previewId} open={open} fetch={queryYoutubePage} />
      </Drawer>
    </>
  )
}
