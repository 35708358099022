import { Shopping, stealStyle, StealStyleQuery } from '@/services/stealStyle'
import { App, Button, Card, Flex, Skeleton, Upload } from 'antd'
import { useMemo, useState } from 'react'
import { HiOutlineUpload } from 'react-icons/hi'
import DressCode from './components/DressCode'
import { AiOutlineSync } from 'react-icons/ai'

export default function StealStyle() {
  const { message } = App.useApp()
  const [image, setImage] = useState<File | null>(null)
  const [currentShoppingIndex, setCurrentShoppingIndex] = useState<number>(0)
  const [text, setText] = useState<string>('')
  const [queries, setQueries] = useState<StealStyleQuery[]>([])
  const [shopping, setShopping] = useState<{ [key: string]: Shopping[] }>({})
  const [loading, setLoading] = useState<boolean>(false)

  const currentShopping = useMemo<{ [key: string]: Shopping }>(() => {
    return {
      top: shopping['top']?.[currentShoppingIndex],
      bag: shopping['bag']?.[currentShoppingIndex],
      accessories: shopping['accessories']?.[currentShoppingIndex],
      bottom: shopping['bottom']?.[currentShoppingIndex],
      glasses: shopping['glasses']?.[currentShoppingIndex],
      shoes: shopping['shoes']?.[currentShoppingIndex]
    }
  }, [currentShoppingIndex, shopping])

  const handleBeforeUpload = async (file: File) => {
    setLoading(true)
    try {
      setImage(file)

      const response = await stealStyle(file)
      if (!response) {
        throw new Error('No response')
      }

      setQueries(response.queries)
      setShopping(response.shopping)
      setText(response.text)
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }

    return false
  }

  const handleChangeShopping = () => {
    setCurrentShoppingIndex(prev => {
      const nextIndex = prev + 1
      if (nextIndex >= shopping['top']?.length) {
        return 0
      }
      return nextIndex
    })
  }

  return (
    <div style={{ height: 'calc(100vh - 64px)' }} className="overflow-auto">
      <Flex vertical align="center" justify="center" style={{ width: 600, margin: 'auto' }}>
        <Upload showUploadList={false} beforeUpload={handleBeforeUpload}>
          <Button loading={loading} icon={<HiOutlineUpload />}>Upload {image ? 'New' : ''} Image</Button>
        </Upload>
        {loading && (
          <div className="mt-4">
            <Skeleton.Image active />
          </div>
        )}
        {queries.length > 0 && (
          <>
            <Card style={{ margin: '16px auto' }}>
              <div className="flex gap-4 items-center justify-center">
                <div>
                  {image && <img src={URL.createObjectURL(image)} alt="" />}
                </div>
                <DressCode queries={queries} shopping={currentShopping} />
              </div>
              <p className="mt-4">{text}</p>
            </Card>
            <Button className="mt-4" icon={<AiOutlineSync />} size="large" onClick={handleChangeShopping}>Change</Button>
          </>
        )}
      </Flex>
    </div>
  )
}
