import request from '@/utils/request'

export interface Persona {
  id: string
  personaId: number
  name: string
  avatar: string
  bio: string
  intro: string
  category?: string
  background?: string
  interest?: string
  audienceInfo?: string
  contentStyle?: string
  personaDoc?: string
  createdAt: string
  updatedAt: string
}

export interface PersonaResponse {
  _id: string
  persona_id: string
  name: string
  avatar: string
  bio: string
  intro: string
  category?: string
  background?: string
  interest?: string
  audience_info?: string
  content_style?: string
  persona_doc?: string
  created_at: string
  updated_at: string
}

export type PersonaData = Omit<Persona, 'id' | 'createdAt' | 'updatedAt'>

export async function queryPersonas(page: number, pageSize: number) {
  const response = await request.get<{ data: PersonaResponse[]; total: number }>('/personas', {
    page,
    page_size: pageSize
  })

  const list = (response?.data ?? []).map<Persona>(v => ({
    id: v._id,
    personaId: Number(v.persona_id),
    name: v.name,
    avatar: v.avatar,
    bio: v.bio,
    intro: v.intro,
    category: v.category,
    background: v.background,
    interest: v.interest,
    audienceInfo: v.audience_info,
    contentStyle: v.content_style,
    personaDoc: v.persona_doc,
    createdAt: v.created_at,
    updatedAt: v.updated_at
  }))

  return { list, total: response?.total ?? 0 }
}

export async function createPersona(data: PersonaData) {
  return request.post('/personas', {
    persona_id: data.personaId.toString(),
    name: data.name,
    avatar: data.avatar,
    bio: data.bio,
    intro: data.intro,
    category: data.category,
    background: data.background,
    interest: data.interest,
    audience_info: data.audienceInfo,
    content_style: data.contentStyle,
    persona_doc: data.personaDoc
  })
}

export async function updatePersona(id: string, data: PersonaData) {
  return request.post(`/personas/${id}`, {
    persona_id: data.personaId.toString(),
    name: data.name,
    avatar: data.avatar,
    bio: data.bio,
    intro: data.intro,
    category: data.category,
    background: data.background,
    interest: data.interest,
    audience_info: data.audienceInfo,
    content_style: data.contentStyle,
    persona_doc: data.personaDoc
  })
}

export async function deletePersona(id: string) {
  return request.post(`/personas/${id}/delete`)
}
