import { Output } from '@/services/srag'
import { Collapse, Flex, Tag, type CollapseProps } from 'antd'
import { Link } from 'react-router-dom'

export default function MiddleSteps({ steps }: { steps?: Partial<Output['result']['log_dict']> }) {
  if (!steps) {
    return null
  }

  const collapseItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Query Rewrite',
      children: steps.rewrite_query&& (
        <div>
          <p><span className="font-bold">LLM: </span> {steps.rewrite_query.llm_query}</p>
          <p><span className="font-bold">Search:</span> {steps.rewrite_query.search_query}</p>
          <p><span className="font-bold">Cost:</span> {steps.query_rewrite_t}s</p>
        </div>
      )
    },
    {
      key: '2',
      label: 'Crawled Data URLs',
      children: steps.crawl_contents_url && (
        <ul>
          {steps.crawl_contents_url.map((v, i) => (
            <li key={i}>
              <Link to={v} target="_blank">{v}</Link>
            </li>
          ))}
        </ul>
      )
    },
    {
      key: '3',
      label: 'Used Snippt URLs',
      children: steps.use_snippt_url && (
        <ul>
          {steps.use_snippt_url.filter(Boolean).map((v, i) => (
            <li key={i}>
              <Link to={v[0]} target="_blank">{v[1]}</Link>
            </li>
          ))}
        </ul>
      )
    },
    {
      key: '4',
      label: 'Recognize Names',
      children: steps.recognize_names && (
        <Flex gap="4px 0" wrap>
          {steps.recognize_names.map((v, i) => <Tag key={i}>{v}</Tag>)}
        </Flex>
      )
    },
    {
      key: '5',
      label: 'Times',
      children: steps.latency_log_str && (
        <ul>
          {steps.latency_log_str.slice(1, -1).split('\\n').filter(Boolean).map((v, i) => (
            <li key={i}>{v}s</li>
          ))}
        </ul>
      )
    }
  ]

  return <Collapse className="!mb-4" items={collapseItems} bordered={false} />
}