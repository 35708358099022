import { Button, Card, Drawer, Flex, FloatButton, Form, Input, Splitter, Switch } from 'antd'
import Panel from './components/Panel'
import { MdOutlineCompare } from 'react-icons/md'
import { useSragCompare } from '@/services/srag'
import { AiOutlineClose, AiOutlineTool } from 'react-icons/ai'
import { useState } from 'react'
import DebugForm from './components/DebugForm'

const { Panel: SplitterPanel } = Splitter
const { Group: FloatButtonGroup } = FloatButton

export default function SragAsk() {
  const { left, right, compare, compareQuery, submitQuery } = useSragCompare()
  const [open, setOpen] = useState(false)

  const handleCompare = () => {
    const value = !compare

    compareQuery(value)
    if (value) {
      setOpen(true)
    }
  }

  const handleSubmit = () => {
    setOpen(false)

    submitQuery()
  }

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    left.onQueryChange(e.target.value)
    right.onQueryChange(e.target.value)
  }

  return (
    <section className="relative">
      <Splitter>
        <SplitterPanel resizable={false}>
          <div className="px-4">
            <Panel {...left} hideParams={compare} onSubmit={submitQuery} />
          </div>
        </SplitterPanel>
        {compare && (
          <SplitterPanel>
            <div className="px-4">
              <Panel {...right} hideParams onSubmit={submitQuery} />
            </div>
          </SplitterPanel>
        )}
      </Splitter>
      {compare ? (
        <FloatButtonGroup>
          <FloatButton type="primary" icon={<AiOutlineTool />} tooltip="Change Params" onClick={() => setOpen(true)} />
          <FloatButton icon={<AiOutlineClose />} tooltip="Close Compare" onClick={handleCompare} />
        </FloatButtonGroup>
      ) : (
        <FloatButton type="primary" icon={<MdOutlineCompare />} tooltip="Compare" onClick={handleCompare} />
      )}
      {compare && (
        <Drawer open={open} title="Params" size="large" getContainer={false} placement="bottom" onClose={() => setOpen(false)}>
          <Flex justify="center" gap={20}>
            <Card className="w-1/3">
              <DebugForm value={left.params} gateway={left.gateway} onChange={left.onParamsChange} onGatewayChange={left.onGatewayChange} />
              <Form.Item label="With Context" labelCol={{ span: 7 }} labelAlign="left">
                <Switch checked={left.withContext} onChange={val => left.onWithContextChange(val)} />
              </Form.Item>
            </Card>
            <Card className="w-1/3">
              <DebugForm value={right.params} gateway={right.gateway} onChange={right.onParamsChange} onGatewayChange={right.onGatewayChange} />
              <Form.Item label="With Context" labelCol={{ span: 7 }} labelAlign="left">
                <Switch checked={right.withContext} onChange={val => right.onWithContextChange(val)} />
              </Form.Item>
            </Card>
          </Flex>
          <div className="text-center space-y-4 w-1/2 mx-auto mt-4">
            <Input value={left.query} placeholder="Enter your query here" onPressEnter={handleSubmit} onChange={handleQueryChange} />
            <Button type="primary" onClick={handleSubmit}>Submit</Button>
          </div>
        </Drawer>
      )}
    </section>
  )
}