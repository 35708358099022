import { processTrendPage, retryTrendPage, TrendPageStatus, type TrendPage } from '@/services/trendPage'
import { App, Button, Popconfirm, Space, Tooltip } from 'antd'
import { useState } from 'react'
import { AiOutlineReload } from 'react-icons/ai'
import { HiOutlineDocument, HiOutlinePlay } from 'react-icons/hi2'

export default function TrendListButton({ page, onPreview, onSuccess }: { page: TrendPage; onPreview: (id: string) => void; onSuccess?: () => void }) {
  const { message } = App.useApp()
  const [loading, setLoading] = useState(false)

  const handleCreate = async () => {
    setLoading(true)
    try {
      await processTrendPage(page.id)

      onSuccess?.()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handlePreview = () => {
    onPreview(page.id)
  }

  const handleRetry = async (id: string) => {
    try {
      await retryTrendPage(id)
      onSuccess?.()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    }
  }

  return (
    <Space>
      {page.status === TrendPageStatus.Pending && (
        <Tooltip title="Create Page">
          <Button type="primary" icon={<HiOutlinePlay />} loading={loading} onClick={handleCreate} />
        </Tooltip>
      )}
      {page.brief && (
        <Tooltip title="View Markdown">
          <Button icon={<HiOutlineDocument />} onClick={handlePreview} />
        </Tooltip>
      )}
      {page.status === TrendPageStatus.Failed && (
        <Popconfirm
          title="Are you sure you want to retry this trend page?"
          onConfirm={() => handleRetry}
        >
          <Tooltip title="Retry">
            <Button type="primary" danger icon={<AiOutlineReload />} />
          </Tooltip>
        </Popconfirm>
      )}
    </Space>
  )
}
