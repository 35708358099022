import { Button, Col, Input, Row } from 'antd'
import { HiOutlineMinus, HiOutlinePlus } from 'react-icons/hi'
import PromptSearch from './PromptSearch'
import { type AgentPrompt } from '@/services/agent'

export default function Prompts({ value, onChange }: { value: AgentPrompt[]; onChange: (value: AgentPrompt[]) => void }) {
  const handleAdd = () => {
    onChange([...value, { key: '', promptId: '', promptName: '' }])
  }

  const handleKeyChange = (index: number, key: string) => {
    onChange(value.map((v, i) => index === i ? { ...v, key } : v))
  }

  const handlePromptChange = (index: number, prompt: { promptId: string; promptName: string }) => {
    onChange(value.map((v, i) => index === i ? { ...v, promptId: prompt.promptId, promptName: prompt.promptName } : v))
  }

  const handleRemove = (index: number) => {
    onChange(value.filter((_, i) => i !== index))
  }

  return (
    <div>
      <div className="space-y-2 mb-4">
        {value.map((v, i) => (
          <Row key={i} gutter={8}>
            <Col span={6}>
              <Input placeholder="Key" value={v.key} onChange={e => handleKeyChange(i, e.target.value)} />
            </Col>
            <Col flex="auto">
              <PromptSearch value={v} onChange={prompt => handlePromptChange(i, prompt)} />
            </Col>
            <Col>
              <Button icon={<HiOutlineMinus />} onClick={() => handleRemove(i)} />
            </Col>
          </Row>
        ))}
      </div>
      <Button size="small" icon={<HiOutlinePlus />} onClick={handleAdd}>Add</Button>
    </div>
  )
}