import { Spin, Upload, type UploadProps } from 'antd'
import { ReactNode, useState } from 'react'
import { AiOutlineLoading } from 'react-icons/ai'
import { HiOutlinePlus } from 'react-icons/hi'

interface UploaderProps {
  path: string
  value?: string
  className?: string
  drag?: boolean
  children?: ReactNode | ((loading?: boolean) => ReactNode)
  onChange?: (val: string) => void
}

const { Dragger } = Upload

export default function Uploader({ path, value, className, drag, children, onChange }: UploaderProps) {
  const [loading, setLoading] = useState(false)

  const handleFileChange: UploadProps['onChange'] = info => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    
    if (info.file.status === 'done') {
      if (onChange) {
        onChange(info.file.response)
      }
      setLoading(false)
    }
  }

  const renderChildren = () => {
    if (value) {
      return <img className={className} src={value} alt="" />
    }

    if (children) {
      return typeof children === 'function' ? children(loading) : children
    }

    return (
      <button type="button" className="w-28 h-28 bg-gray-100 rounded-lg">
        <div className="flex items-center justify-center">
          {loading ? <AiOutlineLoading className="animate-spin" /> : <HiOutlinePlus />}
        </div>
        <p className="mt-2">Upload</p>
      </button>
    )
  }

  const props: UploadProps = {
    action: `${import.meta.env.VITE_DASHBOARD_GATEWAY_HOST}/v1/images`,
    accept: '.jpeg,.jpg,.png,.ico',
    showUploadList: false,
    maxCount: 1,
    data: { path },
    onChange: handleFileChange
  }

  if (drag) {
    return (
      <Dragger {...props}>
        <Spin spinning={!!value && loading}>
          {renderChildren()}
        </Spin>
      </Dragger>
    )
  }

  return (
    <Upload {...props}>
      <Spin spinning={!!value && loading}>
        {renderChildren()}
      </Spin>
    </Upload>
  )
}