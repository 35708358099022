import { batchCreatePage, type PageData } from '@/services/page'
import markdownJSON from '@/utils/markdownJSON'
import { App, Button, Modal, Upload, type UploadProps } from 'antd'
import { useState } from 'react'
import { HiOutlineUpload } from 'react-icons/hi'

const readFile = (file: File) => {
  return new Promise<PageData | null>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async e => {
      if (!e.target?.result || typeof e.target.result !== 'string') {
        resolve(null)
        return
      }

      resolve(markdownJSON(e.target.result))
    }
    reader.onerror = error => reject(error)
    reader.readAsText(file)
  })
}

export default function UploadMarkdownFiles({ onUploaded }: { onUploaded: () => void }) {
  const { message } = App.useApp()
  const [pages, setPages] = useState<{ [key: string]: PageData }>({})
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const handleBeforeUpload: UploadProps['beforeUpload'] = async file => {
    const data = await readFile(file)

    if (data) {
      setPages(prev => ({ ...prev, [file.name]: data }))
    }

    setOpen(true)

    return false
  }

  const handleConfirm = async () => {
    if (!Object.values(pages).length) {
      return
    }

    setLoading(true)
    try {
      await batchCreatePage(Object.values(pages))

      setOpen(false)
      setPages({})

      onUploaded()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Upload
        accept=".md"
        multiple
        showUploadList={false}
        beforeUpload={handleBeforeUpload}
      >
        <Button icon={<HiOutlineUpload />} size="large" loading={loading}>Upload Markdown</Button>
      </Upload>
      <Modal title="Upload Markdown" open={open} onOk={handleConfirm} okButtonProps={{ loading }} onCancel={() => setOpen(false)}>
        <p>Are you sure you want to upload {Object.values(pages).length} files?</p>
      </Modal>
    </>
  )
}