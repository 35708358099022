import request from '@/utils/request'

export interface SheetRow {
  id: number
  department: string
  section: string
  tab: string
  title: string
  link: string
  owner: string
}

export function querySheets() {
  return request.get<string[][]>('/sheets')
}