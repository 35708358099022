import request, { stream } from '@/utils/request'
import type { Page, PageResponse } from './page'

export enum TrendPageStatus {
  CreatingBrief = 'creating_brief',
  Pending = 'pending',
  Processing = 'processing',
  Failed = 'failed',
  Success = 'success'
}

export interface TrendPage {
  id: string
  trend: string
  brief: string
  pageId?: string
  markdown?: string
  status: TrendPageStatus
  updatedAt: string
}

export interface TrendPageResponse {
  id: string
  trend: string
  brief: string
  page_id?: string
  markdown?: string
  status: TrendPageStatus
  updated_at: string
}

export function createTrendBriefStream(trend: string, sourceType: string) {
  return stream(`${import.meta.env.VITE_DASHBOARD_GATEWAY_HOST}/v1/trend_pages/brief`, { trend, source_type: sourceType, stream: true })
}

export function createTrendBrief(trend: string, filename: string, sourceType: string) {
  return request.post('/trend_pages/brief', { trend, filename, source_type: sourceType })
}

export function createTrendPages(trend: string, sourceType: string, briefs: string[]) {
  return request.post('/trend_pages', { trend, source_type: sourceType, briefs })
}

export async function queryTrendPages(page: number, pageSize: number, status?: string, filename?: string) {
  const res = await request.get<{ data: TrendPageResponse[]; total: number; pages: PageResponse[] }>('/trend_pages', {
    page,
    page_size: pageSize,
    status,
    filename
  })

  const list = (res?.data ?? []).map<TrendPage>(item => ({
    id: item.id,
    trend: item.trend,
    brief: item.brief,
    pageId: item.page_id,
    status: item.status,
    updatedAt: item.updated_at
  }))

  const pages = (res?.pages ?? []).map<Page>(item => ({
    id: item._id,
    status: item.status,
    coverImage: item.cover_image,
    outline: {
      postTitle: item.outline?.post_title ?? '',
      postSubtitle: item.outline?.post_subtitle ?? '',
      intro: item.outline?.intro ?? '',
      body: item.outline?.body?.map(v => ({ chapterTitle: v.chapter_title })) ?? []
    },
    createdAt: item.created_at,
    updatedAt: item.updated_at
  }))

  return { list, total: res?.total ?? 0, pages }
}

export async function queryTrendPage(id: string): Promise<TrendPage | null> {
  const res = await request.get<TrendPageResponse>(`/trend_pages/${id}`)
  if (!res) {
    return null
  }

  return {
    id: res.id,
    trend: res.trend,
    brief: res.brief,
    markdown: res.markdown,
    status: res.status,
    updatedAt: res.updated_at
  }
}

export function processTrendPage(id: string) {
  return request.post(`/trend_pages/${id}/process`)
}

export function retryTrendPage(id: string) {
  return request.post(`/trend_pages/${id}/retry`)
}

export function batchTrendPages(ids: string[]) {
  return request.post('/batch/trend_pages', ids)
}

export function batchRetryTrendPages(ids: string[]) {
  return request.post('/batch/retry_trend_pages', ids)
}
