import Uploader from '@/components/Uploader'
import type { PersonaData } from '@/services/persona'
import { Form, type FormInstance, Input, InputNumber } from 'antd'

const { Item: FormItem } = Form
const { TextArea } = Input

export default function ItemForm({ form }: { form: FormInstance<PersonaData> }) {
  return (
    <Form form={form} labelCol={{ span: 4 }}>
      <FormItem<PersonaData>
        label="Person ID"
        name="personaId"
        rules={[{ required: true, message: 'Please enter the person ID' }]}
      >
        <InputNumber min={1} />
      </FormItem>
      <FormItem<PersonaData>
        label="Avatar"
        name="avatar"
        rules={[{ required: true, message: 'Please upload the avatar' }]}
      >
        <Uploader path="page-author" />
      </FormItem>
      <FormItem<PersonaData>
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter the name' }]}
      >
        <Input />
      </FormItem>
      <FormItem<PersonaData>
        label="Bio"
        name="bio"
        rules={[{ required: true, message: 'Please enter the bio' }]}
      >
        <Input />
      </FormItem>
      <FormItem<PersonaData>
        label="Introduction"
        name="intro"
        rules={[{ required: true, message: 'Please enter the introduction' }]}
      >
        <TextArea rows={4} />
      </FormItem>
      <FormItem<PersonaData>
        label="Category"
        name="category"
      >
        <Input />
      </FormItem>
      <FormItem<PersonaData>
        label="Background"
        name="background"
      >
        <Input />
      </FormItem>
      <FormItem<PersonaData>
        label="Interest"
        name="interest"
      >
        <Input />
      </FormItem>
      <FormItem<PersonaData>
        label="Content Style"
        name="contentStyle"
      >
        <Input />
      </FormItem>
      <FormItem<PersonaData>
        label="Audience Info"
        name="audienceInfo"
      >
        <TextArea rows={4} />
      </FormItem>
      <FormItem<PersonaData>
        label="Persona Doc"
        name="personaDoc"
      >
        <TextArea rows={4} />
      </FormItem>
    </Form>
  )
}