import { type Key, useCallback, useState } from 'react'
import TableList, { TableListProps } from '@/components/TableList'
import { createPage, PageStatus, queryPages, updatePagePersona, updatePageStatus, type Page } from '@/services/page'
import { App, Button, type FormInstance, Popconfirm, TableProps } from 'antd'
import dayjs from 'dayjs'
import MarkdownForm from './components/MarkdownForm'
import { HiOutlineEyeOff } from 'react-icons/hi'
import markdownJSON from '@/utils/markdownJSON'
import ListHeaderButtons from './components/ListHeaderButtons'
import useTable from '@/hooks/useTable'
import PageLink from './components/PageLink'

export default function MarkdownToPage() {
  const { message } = App.useApp()
  const [selectedRows, setSelectedRows] = useState<Page[]>([])
  const [isPosting, setIsPosting] = useState(false)
  const { tableProps, fetchList } = useTable(
    useCallback((page, pageSize) => queryPages(page, pageSize), []),
    10
  )

  const handleSubmit = async (form: FormInstance<{ markdown: string; personaId: string }>, record?: Page, isEdit?: boolean) => {
    const val = await form.validateFields()

    if (isEdit) {
      if (!record) {
        throw new Error('Record not found')
      }

      await updatePagePersona(val.personaId, {
        personId: val.personaId,
        outline: record.outline
      })
    } else {
      await createPage(markdownJSON(val.markdown))
    }

    fetchList()
  }

  const handleDraft = async (pageId: string) => {
    setIsPosting(true)
    try {
      await updatePageStatus(pageId, 'draft')

      fetchList()
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message)
      }
    } finally {
      setIsPosting(false)
    }
  }

  const handleSelect = (_: Key[], selectedRows: Page[]) => {
    setSelectedRows(selectedRows)
  }

  const columns: TableProps<Page>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Cover',
      dataIndex: 'coverImage',
      key: 'coverImage',
      render: coverImage => <img className="h-20" src={coverImage} alt="cover" />
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => <PageLink id={record.id} title={record.outline?.postTitle} status={record.status} />
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: updatedAt => dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss')
    }
  ]

  const tableListProps: TableListProps<Page, { markdown: string; personaId: string }> = {
    tableProps: {
      ...tableProps,
      columns,
      rowSelection: {
        selectedRowKeys: selectedRows.map(row => row.id),
        onChange: handleSelect
      },
    },
    formTitle: 'Page',
    headerActions: <ListHeaderButtons selectedRows={selectedRows} onSuccess={fetchList} />,
    hideEdit: record => record.status === PageStatus.PUBLISHED,
    listActions: record => record.status === PageStatus.PUBLISHED && (
      <Popconfirm
        title="Are you sure you want to unpublish this page?"
        okText="Yes"
        cancelText="No"
        okButtonProps={{ loading: isPosting }}
        onConfirm={() => handleDraft(record.id)}
      >
        <Button type="primary" icon={<HiOutlineEyeOff />} danger />
      </Popconfirm>
    ),
    onEdit: (form, record) => {
      form.setFieldsValue({
        personaId: record.outline.persona?.id
      })
    },
    onSubmit: handleSubmit
  }

  return (
    <TableList {...tableListProps}>
      {(form, isEdit) => <MarkdownForm form={form} isEdit={isEdit ?? false} />}
    </TableList>
  )
}