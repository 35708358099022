import { batchPublishPage } from '@/services/page'
import { App, Button, Dropdown, type MenuProps } from 'antd'
import { useState } from 'react'
import { AiOutlineReload } from 'react-icons/ai'
import { HiOutlineChevronDown, HiOutlineEye, HiOutlinePlay } from 'react-icons/hi2'

interface BatchButtonsProps {
  batchRetryIds: string[]
  batchProcessIds: string[]
  batchSuccessIds: (string | undefined)[]
  batchRetry: (ids: string[]) => Promise<unknown>
  batchProcess: (ids: string[]) => Promise<unknown>
  onSuccess?: () => void
}

export default function BatchButtons({ batchRetryIds, batchProcessIds, batchSuccessIds, batchRetry, batchProcess, onSuccess }: BatchButtonsProps) {
  const { message } = App.useApp()
  const [loading, setLoading] = useState(false)

  const items: MenuProps['items'] = [
    {
      key: 'retry',
      label: 'Batch Retry',
      icon: <AiOutlineReload />,
      disabled: batchRetryIds.length === 0
    },
    {
      key: 'process',
      label: 'Batch Process',
      icon: <HiOutlinePlay />,
      disabled: batchProcessIds.length === 0
    },
    {
      key: 'publish',
      label: 'Batch Publish',
      icon: <HiOutlineEye />,
      disabled: batchSuccessIds.filter(Boolean).length === 0
    }
  ]

  const handleBatchRetry = async () => {
    try {
      setLoading(true)
      await batchRetry(batchRetryIds)
      onSuccess?.()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleBatchProcess = async () => {
    try {
      setLoading(true)
      await batchProcess(batchProcessIds)
      onSuccess?.()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleBatchPublish = async () => {
    try {
      setLoading(true)
      await batchPublishPage(batchSuccessIds.filter(Boolean) as string[])
      onSuccess?.()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }


  const handleClick: MenuProps['onClick'] = e => {
    switch (e.key) {
      case 'retry':
        handleBatchRetry()
        break
      case 'process':
        handleBatchProcess()
        break
      case 'publish':
        handleBatchPublish()
        break
    }
  }

  return (
    <Dropdown menu={{ items, onClick: handleClick }}>
      <Button size="large" loading={loading}>
        Batch Action
        <HiOutlineChevronDown />
      </Button>
    </Dropdown>
  )
}
